import { useCallback, useEffect, useRef, useState } from "react";
import MainHome from "../../components/MainHome";
import "./../../styles/homePage/patientsList.scss";
import TablePage from "../../components/genericTable/TablePage";
import { useTranslation } from "react-i18next";
import { TableCard } from "../../common/types/tableCardType";
import { ReactComponent as BeforeExam } from "../../common/assats/homeScreens/cardsIcons/beforeExam.svg";
import { ReactComponent as AfterExam } from "../../common/assats/homeScreens/cardsIcons/afterExam.svg";
import { ReactComponent as AfterConsultation } from "../../common/assats/homeScreens/cardsIcons/afterConsultation-2.svg";
import {
  deletePatients,
  exportData,
  getAllBranchPatients,
  patientToggleAction,
} from "../../common/api/apiCalls";
import { PatientDataInterface } from "../../common/types/patientDataType";
import { useLocation, useNavigate } from "react-router-dom";
import Filter from "../../components/common/Filter/Filter";
import { sysConfig } from "../../config";
import { NavigationPaths } from "../../common/consts/navigationPaths";
import { useFilters } from "../../common/contexts/FiltersContext";
import { useToast } from "../../common/contexts/useToast";
import { TableRowBase } from "../../types/Table.interface";
import { debounce } from "../../common/function/generalFunctions";
import { AxiosResponse } from "axios";
import useUserStore from "../../common/store/user.store";
import { RouteEnum } from "../../common/consts/roles";
import { ReactComponent as ExportIconBig } from "../../common/assats/table/export-big.svg";
import DynamicModal from "../../components/common/Modal/DynamicModal";
type SortOptions =
  | "patientName"
  | "identityNumber"
  | "phoneNumber"
  | "examStatus"
  | "birthday"
  | "lastVisit"
  | "gender";

const PatientsList = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [search, setSearch] = useState<string>("");
  const [debouncedSearch, setDebouncedSearch] = useState<string>("");
  const [sortKey, setSortKey] = useState<string>("patientName");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const { activeFilters, clearFilters, handleCardFilters } = useFilters();
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const { showToast } = useToast();
  const location = useLocation();
  const [page, setPage] = useState(1);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const [patientsData, setPatientsData] = useState<TableRowBase[]>([]);
  const [patientCounts, setPatientCounts] = useState(0);

  const [modalVisible, setModalVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalSubtext, setModalSubtext] = useState("")

  const [cards, setCards] = useState<TableCard[]>([]);
  const { canAccessCRUD } = useUserStore();
  const user = useUserStore((state) => state.user);

  const sortResetRef = useRef(false);


  const filtersObject = {
    examStatus: {
      label: "Exam Status",
      options: [
        { name: "BeforeExam", label: "Before Exam" },
        { name: "AfterExam", label: "After Exam" },
        { name: "AfterConsultation", label: "After Consultation" },
      ],
    },
    lastVisit: {
      label: "Last Visit",
      options: [
        { name: "last7Days", label: "Last 7 Days" },
        { name: "last14Days", label: "Last 14 Days" },
        { name: "last30Days", label: "Last 30 Days" },
        { name: "last3Months", label: "Last 3 Months" },
        { name: "last12Months", label: "Last 12 Months" },
        { name: "custom", label: "Custom" },
      ],
      inputType: "date",
    },
    gender: {
      label: "Gender",
      options: [
        { name: "male", label: "Male" },
        { name: "female", label: "Female" },
      ],
    },
    dob: {
      label: "Date of Birth",
      options: [{ name: "custom", label: "Custom" }],
      inputType: "date",
    },
  };

  const fetchData = async () => {
    const response = await getAllBranchPatients({
      sortKey,
      sortOrder,
      filters: activeFilters,
      search: debouncedSearch,
      page,
      limit: 10,
    });

    if (response?.counts?.total) {
      const filteredData = filterData(response?.patients);
      setPatientsData((prevData) => [...prevData, ...filteredData]);
      setIsFetchingMore(false);
    } else if (!response?.counts?.total) {
      setHasMore(false);
      setIsFetchingMore(false);
    }

    setPatientCounts(response?.counts?.total);
    setCardsData(
      response?.counts?.beforeExam,
      response?.counts?.afterExam,
      response?.counts?.afterConsultation
    );
    sortResetRef.current = false;
  };

  const handleSortChange = (key: string, order: "asc" | "desc") => {
    const isKeyChanged = sortKey !== key;
    const isOrderChanged = sortOrder !== order;

    if (!isKeyChanged && !isOrderChanged) return;

    sortResetRef.current = true;
    setPatientsData([]);
    setHasMore(true);
    setSortKey(key);
    setSortOrder(order);
    setPage(1);
  };

  useEffect(() => {
    resetPagination();
  }, [location.pathname, sortKey, sortOrder, debouncedSearch]);

  useEffect(() => {
    fetchData();
  }, [sortKey, sortOrder, activeFilters, debouncedSearch, page]);

  useEffect(() => {
    setPage(1);
    setPatientsData([]);
    setHasMore(true);
  }, [sortKey, sortOrder]);

  const setCardsData = (
    beforeExam: number,
    afterExam: number,
    afterConsultation: number
  ) => {
    setCards([
      {
        headline: "BeforeExam",
        explanation: t("patientsList.cards.BeforeExamExplain"),
        icon: BeforeExam,
        value: beforeExam,
        action: () => {
          handleCardFilters("examStatus", "BeforeExam");
          resetPagination();
        },
      },
      {
        headline: t("patientsList.cards.afterExam"),
        explanation: t("patientsList.cards.afterExamExplain"),
        icon: AfterExam,
        value: afterExam,
        action: () => {
          handleCardFilters("examStatus", "AfterExam");
          resetPagination();
        },
      },
      {
        headline: t("patientsList.cards.afterConsultation"),
        explanation: t("patientsList.cards.afterConsultationExplain"),
        icon: AfterConsultation,
        value: afterConsultation,
        action: () => {
          handleCardFilters("examStatus", "AfterConsultation");
          resetPagination();
        },
      },
    ]);
  };

  const filterData = (data: any) => {
    return data.map((patient: PatientDataInterface) => {
      return {
        patientName: patient.firstName,
        identityNumber: patient.identityNumber,
        phoneNumber: patient.phoneNumber,
        examStatus: t(`general.examStatus.${patient.examStatus}`),
        birthday: patient.birthday,
        lastVisit: patient.lastVisit
          ? new Date(patient.lastVisit).toISOString().split("T")[0]
          : null,
        gender: patient.gender,
        tableProps: {
          id: patient.id,
          selected: false,
        },
      };
    });
  };

  const handleCheckBoxClick = (updatedRow: any) => {
    const rowId = updatedRow.tableProps.id;

    const updatedPatientsData = patientsData.map((row: any) =>
      row.tableProps.id === rowId
        ? {
          ...row,
          tableProps: {
            ...row.tableProps,
            selected: updatedRow.tableProps.selected,
          },
        }
        : row
    );

    setPatientsData(updatedPatientsData);
    if (updatedRow.tableProps.selected) {
      setSelectedRows((prev) => [...prev, updatedRow]);
    } else {
      setSelectedRows((prev) =>
        prev.filter((row) => row.tableProps.id !== rowId)
      );
    }
  };

  const navigateToAddPatient = () => {
    navigate(NavigationPaths.PATIENTS + "/Create");
  };

  const sortKeysArray: SortOptions[] = [
    "patientName",
    "identityNumber",
    "phoneNumber",
    "examStatus",
    "birthday",
    "lastVisit",
    "gender",
  ];

  const debouncedSetSearch = useCallback(
    debounce((value: string) => {
      setDebouncedSearch(value);
    }, sysConfig.DEBOUNCE_TIME),
    []
  );

  const handleSearchChange = (str: string) => {
    setSearch(str);
    debouncedSetSearch(str);
  };

  const resetPagination = () => {
    setPage(1);
    setPatientsData([]);
    setHasMore(true);
    setIsFetchingMore(false);
  };

  const handleFetchMore = () => {
    if (!isFetchingMore && hasMore && !sortResetRef.current) {
      setIsFetchingMore(true);
      setPage((prev) => prev + 1);
    }
  };

  const deleteSelectedPatients = async () => {
    const ids = selectedRows?.map((row) => row.tableProps?.id);
    const canDelete = canAccessCRUD(RouteEnum.PatientEdit, user?.role)
    if (!selectedRows.length || !ids.length || !canDelete) return;
    const response = await deletePatients(ids);
    if (response.success) {
      setSelectedRows([]);
      resetPagination();
      fetchData();
    } else if (response?.data?.errorType) {
      showToast(
        "info",
        t("general.delete.title"),
        t(response?.data?.errorMessage || "general.delete.error")
      );
      setSelectedRows([]);
      resetPagination();
      fetchData();
    } else {
      showToast(
        "error",
        t("general.delete.title"),
        t(response?.data?.errorMessage || "general.delete.error")
      );
    }
  };


  const handleCheckAll = (checked: boolean) => {
    const updatedPatientsData = patientsData.map((row: any) => {
      return {
        ...row,
        tableProps: {
          ...row.tableProps,
          selected: checked,
        },
      };
    });
    setPatientsData(updatedPatientsData);
    setSelectedRows(checked ? updatedPatientsData : []);
  };

  const exportPatients = async (type: string) => {
    try {
      const params = {
        type: "patients",
        formats: type.toLowerCase(),
        sortKey,
        sortOrder,
        filters: activeFilters,
        search: debouncedSearch,
      };

      const response = await exportData("export", params);

      if (response.success && response.data) {
        const axiosResponse = response.data as AxiosResponse<any, any>;

        const contentDisposition = axiosResponse.headers["content-disposition"];
        const contentType = axiosResponse.headers["content-type"];
        const fileName = contentDisposition
          ? contentDisposition.split("filename=")[1]?.replace(/['"]/g, "")
          : contentType.includes("json")
            ? "export.json"
            : contentType.includes("spreadsheet")
              ? "export.xlsx"
              : "export.zip";

        const blob = new Blob([axiosResponse.data], { type: contentType });

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();

        link.remove();
        window.URL.revokeObjectURL(url);
      } else {
        showToast(
          "error",
          t("general.toast.error"),
          t("general.export.exportGeneralError")
        );
      }
    } catch (error) {
      showToast(
        "error",
        t("general.toast.error"),
        t("general.export.exportGeneralError")
      );
    }
  };

  const onEditClick = (row: PatientDataInterface) => {
    const canEdit = canAccessCRUD(RouteEnum.PatientEdit, user?.role)
    canEdit && navigate(`${NavigationPaths.PATIENTS}/edit/${row.identityNumber}`, {
      state: { data: { patientId: row?.tableProps?.id, identityNumber: row.identityNumber }, edit: true }
    });
  }

  const onToggleActionClick = async (row: PatientDataInterface) => {
    const hasAccess = canAccessCRUD(RouteEnum.PatientToggleAction, user?.role)
    if (!hasAccess) return;
    const id = row?.tableProps?.id as string;
    if (!id) return;
    const res = await patientToggleAction([id]);
    if (res.success) {
      resetPagination();
      fetchData();
    } else {
      showToast("error", t("general.toast.error"), t("general.errors.toggleActionError"));
    }
  }

  const confirmAction = () => {
    setModalVisible(false);
    deleteSelectedPatients();
  };

  const showConfirmationModal = () => {
    if (!selectedRows.length) return;
    setModalTitle(t("general.toast.confirmDelete"));
    setModalSubtext(t("general.delete.patientsDelete"));
    setModalVisible(true);
  };

  const onDeleteClick = (row?: PatientDataInterface) => {
    if (row && row?.tableProps?.id) {
      setSelectedRows((prev) => [...prev, row]);
    } else return;
    setModalTitle(t("general.toast.confirmDelete"));
    setModalSubtext(t("general.delete.patientsDelete"));
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
    setSelectedRows([]);
  }

  return (
    <MainHome>
      {modalVisible && (
        <DynamicModal
          title={modalTitle}
          icon={<ExportIconBig />}
          subtext={modalSubtext}
          onClose={() => closeModal()}
          buttonText="Confirm"
          onButtonClick={confirmAction}
        />
      )}
      <Filter
        isOpen={isFiltersOpen}
        filtersObject={filtersObject}
        handleFilterClose={() => {
          setIsFiltersOpen(false);
        }}
        filterTrigger={resetPagination}
      />

      <TablePage
        hasMore={hasMore}
        isFetchingMore={isFetchingMore}
        fetchMore={handleFetchMore}
        tableHeadline={t(`patientsList.patientsList`)}
        headerHeadline={t("patientsList.patients")}
        viewAllAction={() => {
          clearFilters();
          resetPagination();
        }}
        cards={cards}
        createPath="PatientCreate"
        className={`${isFiltersOpen ? "filter-open" : ""}`}
        totalCount={patientCounts}
        trashAction={showConfirmationModal}
        onCheckBoxClick={handleCheckBoxClick}
        filterAction={() => {
          setIsFiltersOpen(!isFiltersOpen);
        }}
        searchValue={search}
        handleCheckAll={(e) => {
          handleCheckAll(e);
        }}
        setSearchValue={handleSearchChange}
        searchPlaceholder={t("patientsList.Search_by_Name_or_ID")}
        buttonText={t("patientsList.AddPatient")}
        buttonAction={() => {
          navigateToAddPatient();
        }}
        exportAction={exportPatients}
        isExport={true}
        setSortKey={(key) => handleSortChange(key, sortOrder)}
        setSortOrder={(order) => handleSortChange(sortKey, order)}
        sortKey={sortKey}
        sortOrder={sortOrder}
        tableData={patientsData}
        canEdit={canAccessCRUD(RouteEnum.PatientEdit, user?.role)}
        canDelete={canAccessCRUD(RouteEnum.PatientDelete, user?.role)}
        canToggleActive={canAccessCRUD(RouteEnum.PatientToggleAction, user?.role)}
        onEditClick={(row: PatientDataInterface) => { onEditClick(row) }}
        onToggleActionClick={(row: PatientDataInterface) => { onToggleActionClick(row) }}
        onDeleteClick={(row: PatientDataInterface) => { onDeleteClick(row) }}
        sortKeysArray={
          sortKeysArray
            ? sortKeysArray.map((value) => {
              return { name: t(`patientsList.sort.${value}`), val: value };
            })
            : []
        }
        onRowClick={(row: PatientDataInterface) =>
          navigate(`${NavigationPaths.PATIENTS}/view/${row.identityNumber}`)
        }
      // exportAction={exportPatients}
      // isExport={true}
      />
    </MainHome>
  );
};

export default PatientsList;
