import { DevicePropsGeneralInformation } from "../../common/interfaces/Devices.interface";
import {SitePropsGeneralInformation } from "../../common/interfaces/Sites.interface";
import { InfoFieldsBuilderInterface } from "../common/InfoFieldsBuilder";

export const deviceInfoFields = (
  data: DevicePropsGeneralInformation
): InfoFieldsBuilderInterface[] => [
  {
    title: "general.filters.relatedSite",
    textArray: [data?.relatedSite ?? ""],
    colWidth: "col-sm-3",
    translate: true,
  },
  {
    title: "devicesPage.sort.relatedSiteAdmin",
    textArray: [data?.relatedSiteAdmin ?? ""],
    colWidth: "col-sm-3",
    translate: true,
  },
  {
    title: "devicesPage.sort.fw_Version",
    textArray: [data?.fw_version ?? ""],
    colWidth: "col-sm-3",
    translate: true,
  },
  {
    title: "devicesPage.sort.swVersion",
    textArray: [data?.sw_version ?? ""],
    colWidth: "col-sm-3",
    translate: true,
  },
  {
    title: "devicesPage.create.hwId",
    textArray: [data?.hwId ?? ""],
    colWidth: "col-sm-3",
    translate: true,
  }
];
