import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "../../../styles/components/DoubleDatePicker.scss";
import { useTranslation } from "react-i18next";
import ButtonCustom from "../Button";
import { ReactComponent as CalendarIcon } from "../../../common/assats/icons/calendar.svg";

interface DateFilterProps {
  submitDateRange: (dates: [Date, Date]) => void;
  label?: string;
  isLableChanged?: boolean;
}

const DateFilter: React.FC<DateFilterProps> = ({
  submitDateRange,
  label = "dashboard.allDates",
  isLableChanged
}) => {
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);


  const onSave = () => {
    submitDateRange([state[0].startDate, state[0].endDate]);
    setVisible(false);
  };

  return (
    <div className="date-filter-container">
      <button
        className={"custom-button-date-picker" + (isLableChanged ? " lable-changed" : "")}
        onClick={() => setVisible(true)}
        title={label}
      >
        <CalendarIcon />
        <span>{label}</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="9" viewBox="0 0 14 9" fill="none">
          <path
            d="M7 8C6.91244 8 6.8318 7.9833 6.75806 7.9499C6.67972 7.9165 6.60829 7.86639 6.54378 7.79959L1.17972 2.12372C1.05991 1.99489 1 1.83981 1 1.65849C1 1.53442 1.02765 1.42468 1.08295 1.32924C1.13825 1.22904 1.21429 1.15031 1.31106 1.09305C1.40323 1.03102 1.50922 1 1.62903 1C1.79954 1 1.94931 1.06442 2.07834 1.19325L7 6.40389L11.9217 1.19325C12.0507 1.06442 12.2005 1 12.371 1C12.4908 1 12.5968 1.03102 12.6889 1.09305C12.7857 1.15031 12.8618 1.22904 12.9171 1.32924C12.9724 1.42468 13 1.53442 13 1.65849C13 1.83981 12.9378 1.99489 12.8134 2.12372L7.45622 7.79959C7.39171 7.86639 7.32258 7.9165 7.24885 7.9499C7.17051 7.9833 7.08756 8 7 8Z"
            fill="#C8C8C8"
            stroke="#757575"
            strokeWidth="1.5"
          />
        </svg>
      </button>
      <Dialog
        visible={visible}
        onHide={() => setVisible(false)}
        className="custom-date-dialog"
        modal
        showHeader={false}
        resizable={false}
        dismissableMask
        style={{
          width: "min-content",
          maxWidth: "100%",
          alignItems: "start",
          minHeight: "475px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div className="p-fluid">
          <div className="p-col-9 d-flex flex-row justify-content-center">
            <DateRangePicker
              onChange={(item) => {
                const { selection } = item;
                if (!selection || !selection?.startDate || !selection?.endDate) return;
                setState([{ startDate: selection.startDate, endDate: selection.endDate, key: "selection" }]);
              }}
              moveRangeOnFirstSelection={false}
              months={2}
              ranges={state}
              direction="horizontal"
              preventSnapRefocus={true}
              calendarFocus="forwards"
              showDateDisplay={false}
              inputRanges={[]}

            />
          </div>
        </div>

        <div className="d-flex justify-content-end calendar-buttons-container">
          <ButtonCustom
            type="button"
            onClick={() => setVisible(false)}
            className="custom-cancel-btn btn-sm"
            context={t("general.buttons.cancel")}
          />
          <ButtonCustom
            type="button"
            onClick={onSave}
            className="btn-primary btn-sm"
            context={t("general.buttons.save")}
          />
        </div>
      </Dialog>
    </div>
  );
};

export default DateFilter;