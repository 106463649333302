import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { Role, roleRouteConfig } from "../common/consts/roles";
import { PrivateRouteProps } from "../types/privateRouteProps.interface";
import { roleRoutePermissions } from "../common/consts/RoleRotePermission";
import useUserStore from "../common/store/user.store";

const PrivateRoute: React.FC<PrivateRouteProps> = ({
    component: Component,
    componentName,
    redirectComponent: Rc,
    redirectPath = "/",
}) => {

    const { user } = useUserStore();
    if (typeof Rc === "function") Rc = <Rc />;
    
    if (user !== null && !user) return <Navigate to={redirectPath} />
    return user === null ?
        <></>
        :
        (!user?.role || !roleRouteConfig[user.role]?.includes(componentName)) ?
            (Rc ?? <Navigate to={redirectPath} />) : typeof Component === "function" ?
                (<Component />) : (Component);
};

export default PrivateRoute;
