import { useEffect, useRef, useState } from 'react'
import MainHome from '../../components/MainHome'
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFilters } from '../../common/contexts/FiltersContext';
import { useToast } from '../../common/contexts/useToast';
import { useDebouncedSearch } from '../../common/hooks/Search';
import { FilterOptionInterface } from '../../common/interfaces/filtersObject.interface';
import { FOTA, IFota, IFotaTableData } from '../../common/interfaces/FOTA.inteface';
import useUserStore from '../../common/store/user.store';
import { sysConfig } from '../../config';
import Filter from "../../components/common/Filter/Filter";
import { TableHeaderTabs } from '../../common/types/tableCardType';
import TablePage from '../../components/genericTable/TablePage';
import { RouteEnum } from '../../common/consts/roles';
import { abortUploadFota, completeUploadFota, exportData, generatePresignedUrlsFota, getFOTAData, initiateUploadFota } from '../../common/api/apiCalls';
import DynamicModal from '../../components/common/Modal/DynamicModal';
import CustomTextInput from '../../components/common/inputs/CustomTextInput';
import "../../styles/components/FOTA.scss";
import CustomFileInput from '../../components/common/inputs/CustomFileInput';
import { validateFile } from '../../common/consts/filesMegicNumbers';
import { formatDate } from '../../common/function/formatDate';
import { AxiosResponse } from 'axios';

type SortOptions =
    | "VersionName"
    | "VersionDate"
    | "Type";

const sortKeysArray: SortOptions[] = [
    "VersionName",
    "VersionDate",
    "Type",
];


const Fota = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { search, debouncedSearch, handleSearchChange } = useDebouncedSearch(
        sysConfig.DEBOUNCE_TIME
    );
    const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
    const [selectedRows, setSelectedRows] = useState<any[]>([]);
    const { showToast } = useToast();

    const [sortKey, setSortKey] = useState<string>("versionName");
    const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");

    const location = useLocation();

    const { activeFilters, clearFilters } = useFilters();

    const [firmwareData, setFirmwareData] = useState<IFotaTableData[]>([]);
    const [softwareData, setSoftwareData] = useState<IFotaTableData[]>([]);

    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [isFetchingMore, setIsFetchingMore] = useState(false);
    const [activeTab, setActiveTab] = useState<FOTA>(FOTA.FIRMWARE);

    const sortResetRef = useRef(false);

    const { canAccessCRUD } = useUserStore();
    const user = useUserStore((state) => state.user);

    const [isDynamicCreateModalOpen, setIsDynamicCreateModalOpen] = useState<boolean>(false);
    const [currentStep, setCurrentStep] = useState(0);
    const [selectedOptions, setSelectedOptions] = useState<any>({});
    const [isUploading, setIsUploading] = useState<boolean>(false);

    const [uploadProgress, setUploadProgress] = useState<number>(0);
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);

    const [filtersObject] = useState<FilterOptionInterface>({
        versionDate: {
            label: t("fota.filters.versionDate"),
            options: [
                { name: "last7Days", label: "Last 7 Days" },
                { name: "last14Days", label: "Last 14 Days" },
                { name: "last30Days", label: "Last 30 Days" },
                { name: "last3Months", label: "Last 3 Months" },
                { name: "last12Months", label: "Last 12 Months" },
                { name: "custom", label: "Custom" },
            ],
            inputType: "date",
        },
    });

    const steps = [
        {
            state: "type",
            title: t("table.version.titles.updateTitle"),
            subtext: t("fota.create.selectTypeContext"),
            options: [
                { label: t("table.version.buttons.firmware"), value: FOTA.FIRMWARE },
                { label: t("table.version.buttons.software"), value: FOTA.SOFTWARE },
            ],
            buttons: [
                { label: t("general.buttons.cancel"), value: "cancel" },
                { label: t("general.buttons.next"), value: "next" },
            ],
            requierdSelection: true
        },
        {
            state: "versionName",
            title: t("table.version.titles.updateTitleSecond"),
            subtext: t("fota.create.selectVersionContext"),
            inputType: "text",
            buttons: [
                { label: t("general.buttons.previous"), value: "previous" },
                { label: t("general.buttons.next"), value: "next" },
            ],
            requierdSelection: true
        },
        {
            state: "file",
            title: t("table.version.titles.updateTitleFourth"),
            subtext: t("fota.create.uploadFileContext"),
            buttons: [
                { label: t("general.buttons.previous"), value: "previous" },
                { label: t("general.buttons.next"), value: "next" },
            ],
            requierdSelection: true
        },
        {
            state: "selectedData",
            title: t("fota.create.detailsTitle"),
            subtext: t("fota.create.selectedDetailsContext"),
            buttons: [
                { label: t("general.buttons.previous"), value: "previous" },
                { label: t("general.buttons.save"), value: "createFota" },
            ]
        },
    ];

    useEffect(() => {
        getFotaData();
    }, [sortKey, sortOrder, activeFilters, debouncedSearch, page]);

    const FotaTabs: TableHeaderTabs[] = [
        {
            value: t("fota.tabs.firmware"),
            action: (type) => changeTab(type),
            type: "firmware"
        },
        {
            value: t("fota.tabs.software"),
            action: (type) => changeTab(type),
            type: "software"
        },
    ]

    const changeTab = (type: string) => {
        const fotaType = type === "firmware" ? FOTA.FIRMWARE : FOTA.SOFTWARE;
        setActiveTab(fotaType);
        resetPagination();
        getFotaData();
        clearFilters();
    }

    const getFotaData = async () => {
        try {
            setUploadProgress(0);
            const response = await getFOTAData({
                sortKey: sortKey.toLowerCase(),
                sortOrder: sortOrder.toLowerCase(),
                filters: activeFilters,
                search: debouncedSearch,
                page,
                limit: 10,
            },
                activeTab
            )

            if (response.success && response.data && response.data?.[activeTab]?.length) {
                const filterdFirmwareData = filterData(response.data?.firmware || []);
                const filterdSoftwareData = filterData(response.data?.software || []);
                if (page === 1) {
                    setFirmwareData(filterdFirmwareData);
                    setSoftwareData(filterdSoftwareData);
                } else {
                    setFirmwareData((prevData) => [...prevData, ...filterdFirmwareData]);
                    setSoftwareData((prevData) => [...prevData, ...filterdSoftwareData]);
                }
                const activeTabData = response.data?.[activeTab];
                setHasMore(activeTabData.length > 0);

            } else {
                setHasMore(false);
            }
        } catch (error) {
            showToast(
                "error",
                t("general.toast.error"),
                t("general.errors.generalError")
            );
        } finally {
            setIsFetchingMore(false);
        }
        sortResetRef.current = false;
    }

    useEffect(() => {
        resetPagination();
    }, [location.pathname, sortKey, sortOrder, debouncedSearch]);

    const filterData = (data: IFota[]) => {
        return data.map((item) => {
            return {
                versionName: item.version,
                versionDate: formatDate(item?.releaseDate, true),
                type: item.type,
                tableProps: {
                    id: item.id
                }
            }
        })
    }


    const resetPagination = () => {
        setPage(1);
        setFirmwareData([]);
        setSoftwareData([]);
        setHasMore(true);
        setSelectedRows([]);
        setIsFetchingMore(false);
    };

    const handleFetchMore = async () => {
        if (!isFetchingMore && hasMore && !sortResetRef.current) {
            setIsFetchingMore(true);
            setPage((prev) => prev + 1);
        }
    }

    const searchChangeHandler = (str: string) => {
        handleSearchChange(str);
        resetPagination();
    };

    const createFotaModal = () => {
        setIsDynamicCreateModalOpen(true);
    }


    const handleCheckAll = (e: any) => {
        switch (activeTab) {
            case FOTA.FIRMWARE:
                const newSelectedRows = firmwareData.map((row: any) => {
                    return {
                        ...row,
                        tableProps: {
                            ...row.tableProps,
                            selected: e,
                        },
                    };
                });
                setSelectedRows(newSelectedRows);
                setFirmwareData(newSelectedRows);
                break;
            case FOTA.SOFTWARE:
                const newSelectedRows1 = softwareData.map((row: any) => {
                    return {
                        ...row,
                        tableProps: {
                            ...row.tableProps,
                            selected: e,
                        },
                    };
                });
                setSelectedRows(newSelectedRows1);
                setSoftwareData(newSelectedRows1);
                break;
            default:
                break;
        }
    };


    const handleSortChange = (key: string, order: "asc" | "desc") => {
        const isKeyChanged = sortKey !== key;
        const isOrderChanged = sortOrder !== order;

        if (!isKeyChanged && !isOrderChanged) return;

        sortResetRef.current = true;
        setFirmwareData([]);
        setSoftwareData([]);
        setHasMore(true);
        setSortKey(key);
        setSortOrder(order);
        setPage(1);
    };

    const handleCheckBoxClick = (row: any) => {
        switch (activeTab) {
            case FOTA.FIRMWARE:
                const newSelectedRows = firmwareData.map((item) => {
                    if (item.tableProps.id === row.tableProps.id) {
                        return {
                            ...item,
                            tableProps: {
                                ...item.tableProps,
                                selected: !item.tableProps.selected,
                            },
                        };
                    }
                    return item;
                });
                setSelectedRows(newSelectedRows);
                setFirmwareData(newSelectedRows);
                break;
            case FOTA.SOFTWARE:
                const newSelectedRows1 = softwareData.map((item) => {
                    if (item.tableProps.id === row.tableProps.id) {
                        return {
                            ...item,
                            tableProps: {
                                ...item.tableProps,
                                selected: !item.tableProps.selected,
                            },
                        };
                    }
                    return item;
                });
                setSelectedRows(newSelectedRows1);
                setSoftwareData(newSelectedRows1);
                break;
            default:
                break;
        }
    }

    const handleDeleteOnClick = (row: any) => {
        console.log("delete row", row);
    }

    const handleCloseModal = () => {
        setIsDynamicCreateModalOpen(false);
        setCurrentStep(0);
        setSelectedOptions({});
    };

    const handleButtonClick = (value: string) => {
        if (value === "next") {
            nextStep();
        } else if (value === "previous") {
            prevStep();
        } else if (value === "createFota") {
            handleUploadFotaFile();
        } else if (value === "cancel") {
            handleCloseModal();
        }
    };

    const prevStep = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
        }
    };

    const handleUploadFotaFile = async () => {
        try {
            const file = selectedOptions.file;
            setIsUploading(true);
            const response = await initiateUploadFota(file.name, selectedOptions.type, selectedOptions.versionName);

            if (response?.success) {
                const { uploadId, key } = response.data;
                const totalParts = Math.ceil(file.size / (5 * 1024 * 1024)); // 5MB per part

                const presignedUrlsResponse = await generatePresignedUrlsFota(key, uploadId, totalParts);

                if (!presignedUrlsResponse.success) {
                    showToast("error", t("general.toast.error"), "Failed to generate presigned URLs");
                    await abortUploadFota(key, uploadId);
                    return;
                }

                await uploadFileInParts(file, uploadId, key, presignedUrlsResponse.urls);
            } else {
                showToast("error", t("general.toast.error"), t(response.data.errorMessage || "general.errors.generalError"));
            }
        } catch (error) {
            showToast("error", t("general.toast.error"), "File upload failed");
        } finally {
            setIsUploading(false);
        }
    };

    const uploadFileInParts = async (
        file: File,
        uploadId: string,
        key: string,
        presignedUrls: { partNumber: number; signedUrl: string }[]
    ) => {
        const partSize = 5 * 1024 * 1024; // 5MB
        const uploadedParts: { ETag: string; PartNumber: number }[] = [];

        for (const { partNumber, signedUrl } of presignedUrls) {
            const start = (partNumber - 1) * partSize;
            const end = Math.min(start + partSize, file.size);
            const filePart = file.slice(start, end);

            try {
                const response = await fetch(signedUrl, {
                    method: "PUT",
                    body: filePart,
                });

                if (!response.ok) {
                    throw new Error(`Upload failed for part ${partNumber}`);
                }

                const eTag = response.headers.get("ETag");
                if (!eTag) throw new Error(`Missing ETag for part ${partNumber}`);

                uploadedParts.push({ ETag: eTag, PartNumber: partNumber });
                setUploadProgress(Math.round((uploadedParts.length / presignedUrls.length) * 100));
            } catch (error) {
                showToast("error", t("general.toast.error"), `Part ${partNumber} upload failed`);
                await abortUploadFota(key, uploadId);
                return;
            }
        }

        const completeResponse = await completeUploadFota(selectedOptions.versionName, key, uploadId, uploadedParts);
        if (!completeResponse.success) {
            showToast("error", t("general.toast.error"), t(completeResponse.data.errorMessage || "general.errors.generalError"));
            return;
        } else {
            handleCloseModal();
            await getFotaData();
        }
    };

    const nextStep = () => {
        if (currentStep < steps.length - 1) {
            if (steps[currentStep].requierdSelection && !selectedOptions[steps[currentStep].state]) {
                showToast("error", t("general.toast.error"), t("general.errors.fillAllFields"));
                return;
            }
            if (steps[currentStep].state === 'file' && !selectedOptions['file']) {
                showToast("error", t("general.toast.error"), t("fota.create.fileError"));
                return;
            }
            setCurrentStep(currentStep + 1);
        }
    };

    const handleSelection = (value: any) => {
        const optionKey = steps[currentStep].state;
        if (optionKey === "versionName") {
            if (value === "" || sysConfig.VERSION_INPUT_REGEX.test(value)) {
                setSelectedOptions((prev: any) => ({
                    ...prev,
                    [optionKey]: value,
                }));
            } else {
                showToast("error", t("general.toast.error"), t("fota.create.invalidVersionError"));
            }
        } else {
            setSelectedOptions((prev: any) => ({
                ...prev,
                [optionKey]: value,
            }));
        }
    };

    const handleFileSelection = async (file: File | null) => {
        if (!file) {
            setSelectedOptions((prev: any) => ({ ...prev, file: null }));
            setIsButtonDisabled(true);
            return;
        }

        const isValid = await validateFile(file);

        if (!isValid) {
            setSelectedOptions((prev: any) => ({ ...prev, file: null }));
            setIsButtonDisabled(true);
            showToast("error", t("general.toast.error"), t("fota.create.invalidFileTypeError"));
            return;
        }

        setSelectedOptions((prev: any) => ({
            ...prev,
            file,
        }));
        setIsButtonDisabled(false);
    };


    const exportAction = async (type: string) => {
        try {
            const params = {
                type: "fota",
                formats: type.toLowerCase(),
                filters: activeFilters,
                search: debouncedSearch,
                sortKey,
                sortOrder,
            }
            const response = await exportData("export", params);

            if (response.success && response.data) {
                const axiosResponse = response.data as AxiosResponse<any, any>;

                const contentDisposition = axiosResponse.headers["content-disposition"];
                const contentType = axiosResponse.headers["content-type"];
                const fileName = contentDisposition
                    ? contentDisposition.split("filename=")[1]?.replace(/['"]/g, "")
                    : contentType.includes("json")
                        ? "export.json"
                        : contentType.includes("spreadsheet")
                            ? "export.xlsx"
                            : "export.zip";

                const blob = new Blob([axiosResponse.data], { type: contentType });

                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", fileName);
                document.body.appendChild(link);
                link.click();

                link.remove();
                window.URL.revokeObjectURL(url);
            } else {
                showToast(
                    "error",
                    t("general.toast.error"),
                    t("general.export.exportGeneralError")
                );
            }
        } catch (error) {
            showToast(
                "error",
                t("general.toast.error"),
                t("general.export.exportGeneralError")
            );
        }
    }

    return (
        <MainHome>
            <Filter
                isOpen={isFilterOpen}
                filtersObject={filtersObject}
                handleFilterClose={() => {
                    setIsFilterOpen(false);
                }}
                filterTrigger={resetPagination}
            />
            {
                isDynamicCreateModalOpen && (
                    <div className="fota-modal-container">
                        <DynamicModal
                            titleInTheHeader={true}
                            title={steps[currentStep].title}
                            subtext={steps[currentStep].subtext}
                            onClose={handleCloseModal}
                            buttonText={steps[currentStep].buttons[1]?.label}
                            onButtonClick={() => handleButtonClick(steps[currentStep].buttons[1]?.value)}
                            secondaryButtonText={steps[currentStep].buttons[0]?.label}
                            onSecondaryButtonClick={() => handleButtonClick(steps[currentStep].buttons[0]?.value)}
                            isPrimaryButtonDisabled={isButtonDisabled || isUploading}
                            isSecondaryButtonDisabled={isUploading}
                        >
                            {isUploading ? (
                                <div className="upload-progress-container">
                                    <p>{t("fota.create.uploadInProgress")}</p>
                                    <progress value={uploadProgress} max="100" />
                                    <p>{uploadProgress}%</p>
                                </div>
                            ) : (
                                <>
                                    {steps?.[currentStep]?.options && steps[currentStep].inputType !== "dropdown" && (
                                        <div className="d-flex flex-column align-items-start">
                                            {steps[currentStep].options?.map((option) => (
                                                <label key={option.value} style={{ display: "block", marginBottom: "10px" }}>
                                                    <input
                                                        type="radio"
                                                        name={steps[currentStep].state}
                                                        value={option.value}
                                                        checked={selectedOptions[steps[currentStep].state] === option.value}
                                                        onChange={() => handleSelection(option.value)}
                                                        className="me-3"
                                                    />
                                                    {option.label}
                                                </label>
                                            ))}
                                        </div>
                                    )}

                                    {steps[currentStep].inputType === "text" && (
                                        <CustomTextInput
                                            value={selectedOptions[steps[currentStep].state] || ''}
                                            name={steps[currentStep].state}
                                            required={true}
                                            showErrors={false}
                                            handleInputChange={(e) => handleSelection(e.target.value)}
                                            label={t('fota.create.versionNumber')}
                                            translateErrors={false}
                                            withPlaceHolder={true}
                                            icon={null}
                                            disabled={false}
                                        />
                                    )}

                                    {steps[currentStep].state === 'file' && (
                                        <CustomFileInput
                                            name="file"
                                            onChange={(file) => handleFileSelection(file)}
                                            required={true}
                                            disabled={false}
                                        />
                                    )}

                                    {currentStep === steps.length - 1 && (
                                        <div className="d-flex flex-column align-items-start">
                                            {Object.entries(selectedOptions).map(([key, value]) => (
                                                <span key={key}>
                                                    <strong>{t(`table.version.details.${key}`)}:</strong>{" "}
                                                    {key === "file" && value instanceof File ? value.name : String(value)}
                                                </span>
                                            ))}
                                        </div>
                                    )}
                                </>
                            )}
                        </DynamicModal>
                    </div>
                )
            }
            <TablePage
                tableHeadline={t("fota.tableOne")}
                cards={[]}
                tabs={FotaTabs}
                customContainerClass="custom-header-container-class"
                tableHeaderTitle={t("fota.title")}
                hasMore={hasMore}
                isFetchingMore={isFetchingMore}
                fetchMore={handleFetchMore}
                createPath="FotaCreate"
                totalCount={0}
                viewAllAction={() => { }}
                filterAction={() => { setIsFilterOpen(!isFilterOpen) }}
                searchValue={search}
                setSearchValue={searchChangeHandler}
                searchPlaceholder={t("fota.search")}
                buttonAction={createFotaModal}
                setSortKey={(key) => handleSortChange(key, sortOrder)}
                setSortOrder={(order) => handleSortChange(sortKey, order)}
                sortKey={sortKey}
                sortOrder={sortOrder}
                tableData={activeTab === FOTA.FIRMWARE ? firmwareData : softwareData}
                extendGapInTable={true}
                handleCheckAll={handleCheckAll}
                onCheckBoxClick={handleCheckBoxClick}
                sortKeysArray={
                    sortKeysArray ? sortKeysArray.map((value) => {
                        return {
                            name: t(`fota.sort.${activeTab}${value}`),
                            val: value
                        }
                    }) : []
                }
                buttonText={t("fota.create.buttonCreate")}
                onRowClick={(row: any) => {
                    console.log('row click')
                }}
                exportAction={() => {
                    console.log('export')
                }}
                trashAction={() => {
                    console.log('trash')
                }}
                isExport={true}
                canDelete={canAccessCRUD(RouteEnum.FotaDelete, user?.role)}
                onDeleteClick={(row: any) => { handleDeleteOnClick(row) }}
            />

        </MainHome>
    )
}

export default Fota
