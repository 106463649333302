import React, { useEffect, useReducer } from "react";
import {
  ClinicalInfoInterface,
  DemographicInfoInterface,
} from "../../../types/PatientData.interface";
import FormBuilder, {
  CustomDateChangeEvent,
} from "../../common/FormBuilder";
import {
  AddPatientFieldConfig,
  AddPatientFieldConfigInterface,
  firstClinicalConditionalFunc,
} from "./AddPatientFieldConfigurations";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { US_STATES } from "../../../common/function/getCountriesArr";
import { calculateBMI } from "../../../common/function/generalFunctions";
import useUserStore from "../../../common/store/user.store";

interface AddPatientFormProps {
  currentStep: number;
  setPatientData: (values: any) => void;
  errors?: { [key: string]: string | null };
  checkForErrors?: (params: {
    checkAll?: boolean;
    name?: keyof (ClinicalInfoInterface & DemographicInfoInterface);
  }) => void;
  patientData: ClinicalInfoInterface & DemographicInfoInterface;
  showErrors: boolean;
  touched: { [key: string]: boolean };
  setTouched: (state: { [key: string]: boolean }) => void;
}

type ActionType =
  | { type: "UPDATE_METRIC_LABELS"; metrics: string }
  | { type: "UPDATE_ADDRESS_FIELDS"; country: string }
  | { type: "UPDATE_CLINICAL_FIELDS"; isSymptomatic: boolean; isChestPain: boolean }
  | { type: "UPDATE_BMI_VALUE"; value: string }
  | { type: "UPDATE_MARTIAL_STATUS"; selectedValue: string }
  | { type: "UPDATE_CONFIG_FOR_NON_CLINICAL" };


const fieldConfigReducer = (
  state: AddPatientFieldConfigInterface[][],
  action: ActionType
): AddPatientFieldConfigInterface[][] => {
  if(!state.length) return state;
  switch (action.type) {
    case "UPDATE_METRIC_LABELS": {
      return state.map((stepFields) => {
        return stepFields?.map((field) => {
          if (field.name === "height") {
            return {
              ...field,
              additionalLable: action.metrics === "imperial"
                ? "Feet & Inches"
                : action.metrics === "metric"
                  ? "Centimeters"
                  : field.label,
            };
          }
          if (field.name === "weight") {
            return {
              ...field,
              additionalLable: action.metrics === "imperial"
                ? "Pounds"
                : action.metrics === "metric"
                  ? "Kilograms"
                  : field.label,
            };
          }
          return field;
        });
      });
    }

    case "UPDATE_ADDRESS_FIELDS": {
      const updatedAddressFields = AddPatientFieldConfig.ADDRESS.map((field) => {
        if (field.name === "state") {
          return {
            ...field,
            type: action.country === "United States" ? "autoComplete" : "text",
            required: action.country === "United States",
            optionsArray: action.country === "United States" ? US_STATES.map((state) => state.name) : [],
          };
        }
        return field;
      });

      return [
        AddPatientFieldConfig.DEMOGRAPHICS,
        updatedAddressFields,
        state[2], // Clinical One
        state[3], // Clinical Two
        state[4], // Clinical Three
        state[5], // Clinical Four
      ];
    }

    case "UPDATE_CLINICAL_FIELDS": {
      const updatedClinicalOne = [
        ...AddPatientFieldConfig.CLINICAL_ONE,
        ...firstClinicalConditionalFunc(action.isChestPain, action.isSymptomatic),
      ];

      return [
        state[0], // Demographics
        state[1], // Address
        updatedClinicalOne,
        state[3], // Clinical Two
        state[4], // Clinical Three
        state[5], // Clinical Four
      ];
    }

    case "UPDATE_BMI_VALUE": {
      return state.map((stepFields, stepIndex) => {
        if (stepIndex !== 2) return stepFields;
        return stepFields.map((field) => {
          if (field.name === "bmi") {
            return {
              ...field,
              value: action.value,
            };
          }
          return field;
        });
      });
    }

    case "UPDATE_CONFIG_FOR_NON_CLINICAL": {
      return [
        AddPatientFieldConfig.DEMOGRAPHICS,
        AddPatientFieldConfig.ADDRESS,
      ];
    }

    default:
      return state;
  }
};

const AddPatientForm = (props: AddPatientFormProps) => {
  const {
    currentStep,
    setPatientData,
    patientData,
    showErrors,
    errors,
    checkForErrors,
    touched,
    setTouched,
  } = props;

  const { user } = useUserStore();
  const [fieldConfigArray, dispatch] = useReducer(fieldConfigReducer, [
    AddPatientFieldConfig.DEMOGRAPHICS,
    AddPatientFieldConfig.ADDRESS,
    AddPatientFieldConfig.CLINICAL_ONE,
    AddPatientFieldConfig.CLINICAL_TWO,
    AddPatientFieldConfig.CLINICAL_THREE,
    AddPatientFieldConfig.CLINICAL_FOUR,
  ]);


  const handleUpdatePatientData = (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
      | CustomDateChangeEvent
  ) => {
    const { name, value } = event.target;

    const formattedValue =
      value instanceof Date ? dayjs(value).format("YYYY-MM-DD") : value || "";

    setTouched({ ...touched, [name]: true });

    setPatientData((prevValues: any) => ({
      ...prevValues,
      [name]: name !== "smokingStatus" ? (value === "Yes" ? true : value === "No" ? false : value) : formattedValue,
    }));
  };

  useEffect(() => {
    if (patientData?.metrics) {
      dispatch({ type: "UPDATE_METRIC_LABELS", metrics: patientData.metrics });
    }
  }, [patientData?.metrics]);

  useEffect(() => {
    if(user && user.role.includes("NonClinicalUser")) {
      dispatch({ type: "UPDATE_CONFIG_FOR_NON_CLINICAL" });
    }
  }, [user && user.role]);


  useEffect(() => {
    if (patientData?.country) {
      dispatch({ type: "UPDATE_ADDRESS_FIELDS", country: patientData.country });

      if (patientData.country === "United States") {
        const validUSAState = US_STATES.some((state) => state.name === patientData.state);

        setPatientData((prevData: any) => ({
          ...prevData,
          state: validUSAState ? prevData.state : "",
        }));
      }
    }
  }, [patientData?.country, patientData?.state]);

  useEffect(() => {
    if (patientData?.height && patientData?.weight) {
      if (patientData?.metrics && patientData?.height && patientData?.weight) {
        const validHeight = Number(patientData.height);
        const validWeight = Number(patientData.weight);
        let bmiValue = 0;
        if (validHeight && validWeight) {
          bmiValue = calculateBMI({
            metrics: patientData.metrics,
            height: validHeight,
            weight: validWeight,
          })
        }
        dispatch({ type: "UPDATE_BMI_VALUE", value: bmiValue.toString() });
        setPatientData((prevData: any) => ({
          ...prevData,
          bmi: bmiValue,
        }));
      }
    }
  }, [patientData?.height, patientData?.weight]);


useEffect(() => {
  dispatch({
    type: "UPDATE_CLINICAL_FIELDS",
    isSymptomatic: !!patientData?.isSymptomatic,
    isChestPain: !!patientData?.chestPain,
  });

  if (!patientData?.isSymptomatic) {
    resetFirstClinicalFields();
  }
}, [patientData?.isSymptomatic, patientData?.chestPain]);


const resetFirstClinicalFields = () => {
  const fieldNamesToReset = firstClinicalConditionalFunc(true, true).map(
    (field) => field.name
  );

  setPatientData((prevData: any) => {
    const updatedData = { ...prevData };

    fieldNamesToReset.forEach((fieldName) => {
      updatedData[fieldName] = null;
    });

    return updatedData;
  });
};

return (
  <div className="patient-form-container">
    <FormBuilder
      errors={errors}
      values={patientData}
      fields={fieldConfigArray[currentStep]}
      handleInputChange={handleUpdatePatientData}
      showErrors={showErrors}
      handleBlur={(
        name?: keyof ClinicalInfoInterface | keyof DemographicInfoInterface
      ) => {
        checkForErrors?.({ checkAll: false });
      }}
    />
  </div>
);
};

export default AddPatientForm;
