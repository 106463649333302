import * as Yup from "yup";

const deviceValidationSchema = Yup.object().shape({
  deviceSerialNumber: Yup.string()
    .required("Device Serial Number is required.")
    .max(50, "Device Serial Number cannot exceed 50 characters."),

  relatedSite: Yup.string()
    .required("Related Site is required."),

  relatedSiteAdmin: Yup.string()
    .required("Related Site Admin is required."),

  fwVersionShort: Yup.string()
    .required("Firmware Version is required.")
    .matches(/^[a-zA-Z0-9.-]+$/, "Invalid firmware version format."),

  swVersionShort: Yup.string()
    .required("Software Version is required.")
    .matches(/^[a-zA-Z0-9.-]+$/, "Invalid software version format."),

  hwId: Yup.string()
    .required("Hardware ID is required.")
    .max(50, "Hardware ID cannot exceed 50 characters."),
});

export default deviceValidationSchema;
