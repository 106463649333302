import React, { useState } from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto"; // Ensure chart behavior
import "../../../styles/components/BarGraph.scss";
import { useTranslation } from "react-i18next";

interface BarGraphProps {
  objectsArray: Array<{ [key: string]: any }>;
  property: string;
  barCount?: number; // Optional prop to define the number of bars (ranges)
}

const predefinedRanges = {
  age: [
    { label: "18–30", min: 18, max: 30 },
    { label: "31–44", min: 31, max: 44 },
    { label: "45–54", min: 45, max: 54 },
    { label: "55–64", min: 55, max: 64 },
    { label: "65–74", min: 65, max: 74 },
    { label: "75–84", min: 75, max: 84 },
    { label: ">85", min: 85, max: Infinity },
  ],
  BMI: [
    { label: "< 18.5", min: 0, max: 18.5 },
    { label: "18.5–24.9", min: 18.5, max: 24.9 },
    { label: "25–29.9", min: 25, max: 29.9 },
    { label: "30–39.9", min: 30, max: 39.9 },
    { label: ">40", min: 40, max: Infinity },
  ],
  triglycerides: [
    { label: "<150", min: 0, max: 150 },
    { label: "150–199", min: 150, max: 199 },
    { label: "200–499", min: 200, max: 499 },
    { label: ">500", min: 500, max: Infinity },
  ],
  bpSystolic: [
    { label: "<120", min: 0, max: 120 },
    { label: "120–129", min: 120, max: 129 },
    { label: "130–139", min: 130, max: 139 },
    { label: ">140", min: 140, max: Infinity },
  ],
  totalCholesterol: [
    { label: "<200", min: 0, max: 200 },
    { label: "200–239", min: 200, max: 239 },
    { label: ">240", min: 240, max: Infinity },
  ],
  sugarLevel: [
    { label: "<100", min: 0, max: 100 },
    { label: "100–125", min: 100, max: 125 },
    { label: ">126", min: 126, max: Infinity },
  ],
};

const BarGraph: React.FC<BarGraphProps> = ({
  objectsArray,
  property,
  barCount = 2,
}) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const { t } = useTranslation();

  const ranges =
    predefinedRanges[property as keyof typeof predefinedRanges] || [];

  const rangeCounts = ranges.map((range: any) => {
    return objectsArray.reduce((count, obj) => {
      const value = obj[property];
      if (value >= range.min && value <= range.max) {
        return count + 1;
      }
      return count;
    }, 0);
  });

  const mainColor = "#2F75DA";


  const dataToGraph = {
    labels: ranges.map((range) => range.label), 
    datasets: [
      {
        data: rangeCounts,
        backgroundColor: ranges.map((_, index) =>
          activeIndex === null || activeIndex === index
            ? mainColor
            : `rgba(47, 117, 218, 0.2)`
        ),
        borderRadius: 10,
        borderWidth: 1,
        borderColor: "#fff",
        hoverBackgroundColor: ranges.map(() => `rgba(47, 117, 218, 0.8)`),
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: { display: false },
      tooltip: {
        callbacks: {
          label: function (tooltipItem: any) {
            const dataValue = tooltipItem.raw;
            return `${tooltipItem.label}: ${dataValue}`;
          },
        },
        displayColors: false,
      },
    },
    scales: {
      x: { grid: { display: false } },
      y: { beginAtZero: true, grid: { display: false } },
    },
    onHover: (event: any, chartElement: any[]) => {
      if (chartElement.length) {
        const index = chartElement[0].index;
        setActiveIndex(index);
      } else {
        setActiveIndex(null);
      }
    },
  };

  return (
    <div className="bar-graph-container">
      <Bar data={dataToGraph} options={options} width={500} height={300} />
    </div>
  );
};

export default BarGraph;
