import React, { useState } from "react";
import { Col, Form, Button } from "react-bootstrap";
import "../../styles/loginStyles/forgetPassword.scss";
import { Link, useNavigate } from "react-router-dom";
import HalfScreenLogo from "../../components/HalfScreenLogo";
import { forgetPassword } from "../../common/api/apiCalls";
import { useToast } from "../../common/contexts/useToast";
import { useTranslation } from "react-i18next";
import { sysConfig } from "../../config";

const ForgetPassword = () => {
  const { showToast } = useToast();
  const [email, setEmail] = useState("");
  const [formErrors, setFormErrors] = useState<{ email?: string }>({});
  const { t } = useTranslation();
  const navigate = useNavigate();

  const SIGN_IN_TEXT_MAIN_TITLE = t("forget_pass.SIGN_IN_TEXT_MAIN_TITLE");
  const SIGN_IN_TEXT_SUB_TITLE = t("forget_pass.SIGN_IN_TEXT_SUB_TITLE");
  const SIGN_IN_TEXT_INPUT_1_PLACEHOLDER = t(
    "forget_pass.SIGN_IN_TEXT_INPUT_1_PLACEHOLDER"
  );
  const SIGN_IN_TEXT_BUTTON_1 = t("forget_pass.SIGN_IN_TEXT_BUTTON_1");
  const SIGN_IN_TEXT_BUTTON_2 = t("forget_pass.SIGN_IN_TEXT_BUTTON_2");
  const SIGN_IN_TEXT_LABEL_1 = t("forget_pass.SIGN_IN_TEXT_LABEL_1");

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // Basic validation for email
    if (!email) {
      setFormErrors({ email: t("userList.fromErrors.userEmailRequired") });
      showToast(
        "error",
        t("general.toast.error"),
        t("userList.fromErrors.userEmailRequired")
      );
      return;
    }

    if (!sysConfig.EMAIL_PATTERN.test(email)) {
      setFormErrors({ email: t("userList.fromErrors.userEmailNotValid") });
      showToast(
        "error",
        t("general.toast.error"),
        t("userList.fromErrors.userEmailNotValid")
      );
      return;
    }

    try {
      const res = await forgetPassword(email);
      if (res?.success) {
        navigate("/otp", { state: { email, otpType: "resetPassword" } });
      } else {
        showToast(
          "error",
          t("general.toast.error"),
          t(res?.data?.errorMessage || "general.toast.error")
        );
      }
    } catch (err) {
      showToast(
        "error",
        t("general.toast.error"),
        t("forget_pass.errorGeneral")
      );
    }
  };

  return (
    <HalfScreenLogo>
      <Col className="right-part">
        <Form
          className="form-container forget-password-container d-flex flex-column align-item-center "
          onSubmit={onSubmit}
        >
          <Form.Group
            className="title-group"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Form.Label className="global-big-label text-1o">
              {SIGN_IN_TEXT_MAIN_TITLE}
            </Form.Label>
            <Form.Label className="global-sub-label text-3o">
              {SIGN_IN_TEXT_SUB_TITLE}
            </Form.Label>
          </Form.Group>
          <Form.Group className="group-spacing" controlId="formBasicEmail">
            <Form.Control
              className="global-input"
              type="email"
              name="email"
              placeholder={SIGN_IN_TEXT_INPUT_1_PLACEHOLDER}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <Form.Text className="text-danger" style={{ marginLeft: "20px" }}>
              {formErrors.email}
            </Form.Text>
          </Form.Group>

          <div className="d-flex justify-content-center">
            <Button
              className="global-button group-spacing"
              variant="primary"
              type="submit"
            >
              {SIGN_IN_TEXT_BUTTON_1}
            </Button>
          </div>

          <Form.Group className="group-center">
            <Form.Label className="global-sub-label global-font-weight-600">
              {SIGN_IN_TEXT_BUTTON_2 + "   "}
            </Form.Label>

            <Link to="/">
              <Form.Label className="global-sub-label global-font-weight-600 text-primary cursor-pointer space">
                {SIGN_IN_TEXT_LABEL_1}
              </Form.Label>
            </Link>
          </Form.Group>
        </Form>
      </Col>
    </HalfScreenLogo>
  );
};

export default ForgetPassword;
