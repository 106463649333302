import React, { useEffect, useState } from "react";
import { sysConfig } from "../../../config";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";

interface CustomDateRangePickerProps {
  onSubmit: (fromDate: string, toDate: string) => void;
  dateRangeValue?: string;
}

interface DateField {
  id: string;
  placeholder: string;
  ariaLabel: string;
  maxLength: number;
}

const dateFields: DateField[] = [
  { id: "month", placeholder: "mm", ariaLabel: "Month", maxLength: 2 },
  { id: "day", placeholder: "dd", ariaLabel: "Day", maxLength: 2 },
  { id: "year", placeholder: "yyyy", ariaLabel: "Year", maxLength: 4 },
];

const CustomDateRangePicker: React.FC<CustomDateRangePickerProps> = ({
  onSubmit,
  dateRangeValue,
}) => {
  const { t } = useTranslation();

  const [fromDate, setFromDate] = useState<Record<string, string>>({
    month: "",
    day: "",
    year: "",
  });
  const [toDate, setToDate] = useState<Record<string, string>>({
    month: "",
    day: "",
    year: "",
  });

  const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);

  const [fieldErrors, setFieldErrors] = useState<Record<string, boolean>>({});

  const validateField = (field: string, value: string, type: "from" | "to") => {
    let isError = false;

    if (field === "month") {
      isError = +value < 1 || +value > 12;
    } else if (field === "day") {
      isError = +value < 1 || +value > 31;
    } else if (field === "year") {
      isError = +value < 1900 || +value > 2100;
    }

    setFieldErrors((prev) => ({
      ...prev,
      [`${type}-${field}`]: isError,
    }));
  };

  const areAllFieldsFilledAndValid = () => {
    const allFields = [...Object.values(fromDate), ...Object.values(toDate)];
    const allFieldsFilled = allFields.every((value) => value.trim() !== "");
    const noFieldErrors = !Object.values(fieldErrors).some((isError) => isError);
    return allFieldsFilled && noFieldErrors;
  };

  const handleInputChange = (
    setDateState: React.Dispatch<React.SetStateAction<Record<string, string>>>,
    field: string,
    value: string,
    type: "from" | "to"
  ) => {
    if (value === "" || sysConfig.NUMERIC_PATTERN.test(value)) {
      setDateState((prev) => ({ ...prev, [field]: value }));
      validateField(field, value, type);
    }
  };

  const handleSubmit = () => {
    const from = `${fromDate.month}/${fromDate.day}/${fromDate.year}`;
    const to = `${toDate.month}/${toDate.day}/${toDate.year}`;
    onSubmit(from, to);
  };

  useEffect(() => {
    if (dateRangeValue) {
      const [from, to] = dateRangeValue.split(" - ");
      const [fromMonth, fromDay, fromYear] = from.split("/");
      const [toMonth, toDay, toYear] = to.split("/");

      setFromDate({
        month: fromMonth,
        day: fromDay,
        year: fromYear,
      });
      setToDate({
        month: toMonth,
        day: toDay,
        year: toYear,
      });
    }
  }, [dateRangeValue]);

  useEffect(() => {
    setSubmitDisabled(!areAllFieldsFilledAndValid());
  }, [fromDate, toDate, fieldErrors]);

  return (
    <div className="custom-date-range-picker-container d-flex flex-column align-items-start ms-2 mt-2">
      <span className="mb-2">{t("general.filters.enterDate")}</span>

      <div className="date-row d-flex flex-column align-items-center justify-content-between mb-2 w-100">
        <span className="align-self-start fw-bold custom-fz mb-1">
          {t("general.filters.from")}
        </span>
        <div className="date-inputs d-flex align-items-center">
          {dateFields.map((field) => (
            <input
              key={`from-${field.id}`}
              type="text"
              placeholder={field.placeholder}
              aria-label={`From ${field.ariaLabel}`}
              maxLength={field.maxLength}
              value={fromDate[field.id]}
              onChange={(e) =>
                handleInputChange(setFromDate, field.id, e.target.value, "from")
              }
              className={
                fieldErrors[`from-${field.id}`] ? "input-error" : ""
              }
            />
          ))}
        </div>
      </div>

      <div className="date-row d-flex flex-column align-items-center justify-content-between mb-2 w-100">
        <span className="align-self-start fw-bold custom-fz mb-1">{t("general.filters.to")}</span>
        <div className="date-inputs d-flex align-items-center justify-content-center">
          {dateFields.map((field) => (
            <input
              key={`to-${field.id}`}
              type="text"
              placeholder={field.placeholder}
              aria-label={`To ${field.ariaLabel}`}
              maxLength={field.maxLength}
              value={toDate[field.id]}
              onChange={(e) =>
                handleInputChange(setToDate, field.id, e.target.value, "to")
              }
              className={fieldErrors[`to-${field.id}`] ? "input-error" : ""}
            />
          ))}
        </div>
      </div>
      <div className="d-flex w-100 justify-content-end pe-4 pt-1">
        <Button
          type="submit"
          className="btn custom-btn d-flex justify-content-center align-items-center"
          onClick={handleSubmit}
          disabled={submitDisabled}
        >
          {t("general.buttons.ok")}
        </Button>
      </div>
    </div>
  );
};

export default CustomDateRangePicker;
