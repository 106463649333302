import { useEffect, useState } from "react";
import MainHome from "../MainHome";
import "./../../styles/homePage/patient.scss";
import { useTranslation } from "react-i18next";
import {
  generatePDFReport,
  getPatient,
  getPatientClinical,
  getPDFReport,
} from "../../common/api/apiCalls";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, Tab, Tabs } from "react-bootstrap";
import InfoFieldsBuilder from "../common/InfoFieldsBuilder";
import { getPatientInfoFields } from "./PatientInfoFields";
import { logos } from "../../common/assats";
import GenericCards from "../common/Cards/GenericCards";
import { ReactComponent as ExamGraphImage } from "../../common/assats/homeScreens/exam_graph.svg";
import { formatDate } from "../../common/function/formatDate";
import { ReactComponent as ExamGraphImageTEMPFILLER } from "../../common/assats/homeScreens/exam_graph_temp_filler.svg";
import CustomTextArea from "../common/inputs/CustomTextArea";
import ButtonCustom from "../common/Button";
import PatientExamPDFViewAndSign from "./PatientExam";
import { useToast } from "../../common/contexts/useToast";
import useUserStore from "../../common/store/user.store";


const allTabs = [
  { key: "demographic", title: "Demographic Information", field: "DEMOGRAPHIC" },
  { key: "clinical1", title: "Clinical Information 1", field: "CLINICAL_ONE" },
  { key: "clinical2", title: "Clinical Information 2", field: "CLINICAL_TWO" },
  { key: "exam", title: "Exam Results", field: null, isExamTab: true },
];

const Patient = () => {
  const { patientId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [data, setData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [cardsConfig, setCardsConfig] = useState<any>([]);
  const [isExamPage, setIsExamPage] = useState<boolean>(false);
  const [examData, setExamData] = useState<any>(null);
  const [examComment, setExamComment] = useState<string>("");
  const [isExamPDF, setIsExamPDF] = useState<boolean>(false);
  const [htmlData, setHtmlData] = useState<string>("");
  const { showToast } = useToast();
  const { user } = useUserStore();

  const isNonClinicalUser = user && user.role.includes("NonClinicalUser");


  const filteredTabs = allTabs.filter((tab) => {
    if (isNonClinicalUser) {
      return tab.key === "demographic" || tab.key === "exam"; // Show only demographic & exams for non-clinical users
    }
    return true; // Show all tabs for other users
  });



  useEffect(() => {
    getPatientData();
  }, [patientId]);

  const editPatient = () => {
    navigate(`/Home/Patients/Edit/${patientId}`, {
      state: {
        data: data,
        edit: true
      }, replace: true
    });
  };

  const cardClick = (exam: any) => {
    if (!exam) return;
    setExamComment(exam.examComment);
    setExamData(exam);
    setIsExamPage(true);
  };

  const getPatientData = async () => {
    setIsLoading(true);

    const patientData = await getPatient(patientId!);
    const patientUid = patientData.id;

    const res = await getPatientClinical(patientUid!);
    const clinicalData = res.data.clinicalData;
    const combinedData = {
      patientId: patientUid,
      ...patientData,
      ...clinicalData,
      lastVisit: new Date(patientData?.lastVisit).toISOString().split("T")[0] ?? "N/A",
    };
    const cards = patientData.exams?.map((exam: any) => {
      return {
        title: t("patientsList.exams.cardTitle"),
        id: exam.id,
        image: (<ExamGraphImage />) as React.ReactNode,
        body: formatDate(exam.examStartDate, false),
        buttonAction: () => cardClick(exam),
      };
    });
    setCardsConfig(cards);
    setData(combinedData);
    setError(null);
    setIsLoading(false);
  };

  const signAndView = async () => {
    showToast(
      "info",
      t("general.toast.inProgress"),
      t("patientsList.exams.preparingExamPdf")
    );
    if (!examData) return;
    const htmlContent = await getPDFReport(examData.id, examComment);
    if (htmlContent && htmlContent?.data?.data) {
      setIsExamPDF(true);
      setHtmlData(htmlContent?.data?.data);
    } else {
      showToast(
        "error",
        t("general.toast.error"),
        t("general.errors.generalError")
      );
    }
  };

  const singPDF = async (signature: string) => {
    showToast(
      "info",
      t("general.toast.inProgress"),
      t("patientsList.exams.signingExamPdf")
    );
    try {
      const res = await generatePDFReport(examData.id, signature);
      if (res.success) {
        showToast(
          "success",
          t("general.toast.success"),
          t("patientsList.exams.pdfIsSent")
        )
        const printWindow = window.open("", "", "width=800,height=600");
        if (printWindow) {
          printWindow.document.open();
          printWindow.document.write(res.data?.htmlContent);
          printWindow.document.close();
          printWindow.print();
        }
        setIsExamPDF(false);
      } else {
        showToast(
          "error",
          t("general.toast.error"),
          t("general.errors.generalError")
        );
      }
    } catch (error) {
      showToast(
        "error",
        t("general.toast.error"),
        t("general.errors.generalError")
      );
    }
  };

  const printPDF = (htmlContent: string) => {
    showToast("info", t("general.toast.inProgress"), "Preparing to print...");
  
    try {
      // Create an iframe dynamically
      const iframe = document.createElement("iframe");
      iframe.style.position = "absolute";
      iframe.style.width = "0px";
      iframe.style.height = "0px";
      iframe.style.border = "none";
      document.body.appendChild(iframe);
  
      const iframeDoc = iframe.contentWindow?.document;
  
      if (iframeDoc) {
        iframeDoc.open();
        iframeDoc.write(htmlContent); // Load content inside iframe
        iframeDoc.close();
  
        iframe.onload = () => {
          iframe.contentWindow?.focus();
          iframe.contentWindow?.print();
        };
      } else {
        throw new Error("Could not access iframe document");
      }
    } catch (error) {
      showToast("error", t("general.toast.error"), "Printing failed");
      console.error("Print error:", error);
    }
  };

  const backFromExamPdf = () => {
    setIsExamPDF(false);
  };

  return (
    <MainHome>
      <div className="patient">
        {isLoading ? (
          <></>
        ) : isExamPDF ? (
          <PatientExamPDFViewAndSign
            htmlContent={htmlData}
            backClick={backFromExamPdf}
            singReport={(signature: string) => singPDF(signature)}
          />
        ) : (
          <>
            <div className="patient-header">
              <div className="identity-info">
                <div className="col-4">
                  <div className="d-flex flex-row align-items-end patient-name">
                    <h1>{`${data?.honorific} ${data?.firstName} ${data?.lastName}`}</h1>
                    <span onClick={editPatient}>{t("general.edit")}</span>
                  </div>
                  <div className="patient-id">
                    <div>{t("patientsList.patientId") + patientId}</div>
                  </div>
                  <div className="additonal-details rounded-status">
                    {t(`general.examStatus.${data.examStatus}`)}
                  </div>
                </div>
                <div className="col-8 d-flex align-items-end">
                  <InfoFieldsBuilder fields={getPatientInfoFields(data).HEADER} />
                </div>
              </div>
            </div>
            <div className="patient-info">
              {isExamPage ? (
                <div className="patient-info-container">
                  <div className="exam-header">
                    <span>
                      <span className="exam-result-title">
                        {t("patientsList.exams.cardTitle")}
                        {": "}
                        {formatDate(examData?.examStartDate, false)}
                      </span>
                    </span>
                    <div className="exam-result-body mb-2">
                      <span className="w-100">{examData.resultNote}</span>
                      <div className="exam-graph-container d-flex justify-content-center align-items-center">
                        <ExamGraphImageTEMPFILLER className="exam-graph-svg" />
                      </div>
                    </div>
                    <div className="exam-footer d-flex justify-content-center align-items-center flex-column">
                      <div className="mb-3 max-width">
                        <CustomTextArea
                          name="examResult"
                          value={examComment}
                          handleInputChange={(e) => {
                            setExamComment(e.target.value);
                          }}
                          label={t("patientsList.exams.comment")}
                          withPlaceHolder={true}
                          showErrors={false}
                        />
                      </div>
                      <div className="col-12 d-flex justify-content-end">
                        <ButtonCustom
                          type="button"
                          onClick={signAndView}
                          className="btn-primary btn-lg"
                          context={t("general.buttons.viewAndSign")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <Tabs defaultActiveKey="demographic" id="patients-info-tabs">
                  {filteredTabs?.map((tab) => (
                    <Tab key={tab.key} eventKey={tab.key} title={tab.title}>
                      <div className="patient-info-container scrollable-container">
                        {tab.isExamTab ? (
                          <GenericCards cardsConfig={cardsConfig} />
                        ) : (
                          tab?.field && tab.field in getPatientInfoFields(data) ? (
                            <InfoFieldsBuilder
                              containerClassName="patient-info-container"
                              fields={getPatientInfoFields(data)[tab.field as keyof ReturnType<typeof getPatientInfoFields>]}
                            />
                          ) : (
                            <p></p>
                          )
                        )}
                      </div>
                    </Tab>
                  ))}
                </Tabs>

              )}
            </div>
          </>
        )}
      </div>
    </MainHome>
  );
};

export default Patient;
