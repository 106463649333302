export type Role =
  | "SuperAdmin"
  | "SiteAdmin"
  | "NonClinicalUser"
  | "ClinicalUser"
  | "RootUser";

export const roles = {
  SuperAdmin: "SuperAdmin" as Role,
  SiteAdmin: "SiteAdmin" as Role,
  NonClinicalUser: "NonClinicalUser" as Role,
  ClinicalUser: "ClinicalUser" as Role,
  RootUser: "RootUser" as Role,
};

export const roleDisplayNames = {
  SuperAdmin: "Super Admin",
  SiteAdmin: "Site Admin",
  ClinicalUser: "Clinical User",
  NonClinicalUser: "Non Clinical User",
  RootUser: "Root User",
};

export enum RouteEnum {
  Home = "Home",
  Dashboard = "Dashboard",
  UserProfile = "UserProfile",
  Logout = "Logout",

  About = "About",
  Support = "Support",
  Contact = "Contact",
  Settings = "Settings",

  // Patient routes and operations
  Patient = "Patient",
  Patients = "Patients",
  PatientCreate = "PatientCreate",
  PatientEdit = "PatientEdit",
  PatientView = "PatientView",
  PatientDelete = "PatientDelete",
  PatientToggleAction = "PatientToggleAction",
  // TODO add more patient routes ( operation )

  // Site routes and operations
  Sites = "Sites",
  SitesPageCreate = "SitesPageCreate",
  SitesPageEdit = "SitesPageEdit",
  SiteView = "SiteView",
  SiteToggleAction = "SiteToggleAction",

  // Users routes and operations
  Users = "Users",
  UsersCreate = "UsersCreate",
  UsersEdit = "UsersEdit",
  UserDelete = "UserDelete",
  UserView = "UserView",
  UserToggleAction = "UserToggleAction",

  // Devices routes and operations
  Devices = "Devices",
  DevicesCreate = "DevicesCreate",
  DevicesEdit = "DevicesEdit",
  DevicesView = "DevicesView",
  DevicesToggleAction = "DevicesToggleAction",

  // FOTA routes and operations
  Fota = "Fota",
  FotaCreate = "FotaCreate",
  FotaEdit = "FotaEdit",
  FotaDelete = "FotaDelete",
  FotaView = "FotaView",
  FotaUpdateVersion = "FotaUpdateVersion",

  // Patients exams
  ExamsView = "ExamsView",
  ExamsGenerate = "ExamsGenerate",
  ExamsMail = "ExamsMail",
}

export const roleRouteConfig: { [role in Role]: string[] } = {
  SuperAdmin: [
    RouteEnum.Home,
    RouteEnum.Dashboard,
    RouteEnum.Fota,
    RouteEnum.Devices,
    RouteEnum.DevicesCreate,
    RouteEnum.DevicesEdit,
    RouteEnum.Sites,
    RouteEnum.About,
    RouteEnum.Support,
    RouteEnum.Contact,
    RouteEnum.Settings,
    RouteEnum.Users,
    RouteEnum.UsersCreate,
    RouteEnum.UsersEdit,
    RouteEnum.UserDelete,
    RouteEnum.SitesPageCreate,
    RouteEnum.UserProfile,
    RouteEnum.FotaCreate,
    RouteEnum.FotaDelete,
  ],
  SiteAdmin: [
    RouteEnum.Home,
    RouteEnum.Dashboard,
    RouteEnum.Patient,
    RouteEnum.PatientCreate,
    RouteEnum.PatientEdit,
    RouteEnum.Devices,
    RouteEnum.About,
    RouteEnum.Support,
    RouteEnum.Contact,
    RouteEnum.Settings,
    RouteEnum.Users,
    RouteEnum.UsersCreate,
    RouteEnum.UsersEdit,
    RouteEnum.UserDelete,
    RouteEnum.UserProfile,
  ],
  ClinicalUser: [
    RouteEnum.Home,
    RouteEnum.Patient,
    RouteEnum.PatientCreate,
    RouteEnum.PatientEdit,
    RouteEnum.Fota,
    RouteEnum.About,
    RouteEnum.Support,
    RouteEnum.Contact,
    RouteEnum.Settings,
    RouteEnum.Sites,
    RouteEnum.Dashboard,
    RouteEnum.UserProfile,
  ],
  NonClinicalUser: [
    RouteEnum.Home,
    RouteEnum.About,
    RouteEnum.Support,
    RouteEnum.Patient,
    RouteEnum.PatientCreate,
    RouteEnum.PatientEdit,
    RouteEnum.Contact,
    RouteEnum.Settings,
    RouteEnum.UserProfile,
  ],
  RootUser: [
    RouteEnum.Home,
    RouteEnum.Dashboard,
    RouteEnum.Fota,
    RouteEnum.Devices,
    RouteEnum.DevicesCreate,
    RouteEnum.DevicesEdit,
    RouteEnum.Sites,
    RouteEnum.About,
    RouteEnum.Support,
    RouteEnum.Contact,
    RouteEnum.Settings,
    RouteEnum.Users,
    RouteEnum.UsersCreate,
    RouteEnum.UsersEdit,
    RouteEnum.UserDelete,
    RouteEnum.SitesPageCreate,
    RouteEnum.UserProfile,
    RouteEnum.FotaCreate,
    RouteEnum.FotaDelete,
  ],
};


export const userMenuAccess: Record<Role, string[]> = {
  ClinicalUser: [
    RouteEnum.Patients,
    RouteEnum.About,
    RouteEnum.Support,
    RouteEnum.Contact,
    RouteEnum.Settings,
    RouteEnum.UserProfile,
    RouteEnum.Logout
  ],
  NonClinicalUser: [
    RouteEnum.Patients,
    RouteEnum.About,
    RouteEnum.Support,
    RouteEnum.Contact,
    RouteEnum.Settings,
    RouteEnum.Logout,
  ],
  SiteAdmin: [
    RouteEnum.Dashboard,
    RouteEnum.Patients,
    RouteEnum.Users,
    RouteEnum.Devices,
    RouteEnum.About,
    RouteEnum.Support,
    RouteEnum.Contact,
    RouteEnum.Settings,
    RouteEnum.Logout,
  ],
  SuperAdmin: [
    RouteEnum.Dashboard,
    RouteEnum.Users,
    RouteEnum.Sites,
    RouteEnum.Devices,
    RouteEnum.Fota,
    RouteEnum.About,
    RouteEnum.Support,
    RouteEnum.Contact,
    RouteEnum.Settings,
    RouteEnum.Logout
  ],
  RootUser: [
    RouteEnum.Dashboard,
    RouteEnum.Users,
    RouteEnum.Sites,
    RouteEnum.Devices,
    RouteEnum.Fota,
    RouteEnum.About,
    RouteEnum.Support,
    RouteEnum.Contact,
    RouteEnum.Settings,
    RouteEnum.Logout
  ],
};