import React, { ReactNode } from "react";
import "../../../styles/components/DynamicModal.scss";
import { ReactComponent as CloseIcon } from "../../../common/assats/icons/closeXIcon.svg";
import ButtonCustom from "../Button";
import { useTranslation } from "react-i18next";

interface DynamicModalProps {
  title: string;
  icon?: React.ReactNode;
  subtext?: string;
  onClose: () => void;
  children?: ReactNode;
  buttonText?: string;
  onButtonClick?: () => void;
  secondaryButtonText?: string;
  onSecondaryButtonClick?: () => void;
  titleInTheHeader?: boolean;
  isPrimaryButtonDisabled?: boolean;
  isSecondaryButtonDisabled?: boolean;
}

const DynamicModal: React.FC<DynamicModalProps> = ({
  title,
  icon,
  subtext,
  onClose,
  children,
  buttonText,
  onButtonClick,
  secondaryButtonText,
  onSecondaryButtonClick,
  titleInTheHeader,
  isPrimaryButtonDisabled,
  isSecondaryButtonDisabled,
}) => {
  const { t } = useTranslation();

  return (
    <div className="modal-overlay">
      <div className="base-modal">
        <div className={titleInTheHeader ? "title-in-header" : "modal-header"}>
          {icon && <div className="modal-icon">{icon}</div>}
          {titleInTheHeader && <h2>{title}</h2>}
          <button className="modal-close" onClick={onClose}>
            <CloseIcon />
          </button>
        </div>

        <div className="modal-body">
          {!titleInTheHeader && <h2>{title}</h2>}
          <p>{subtext}</p>
          {children && <div className="modal-children">{children}</div>}
        </div>

        <div className="modal-footer">
          {secondaryButtonText && (
            <ButtonCustom
              type="button"
              onClick={onSecondaryButtonClick}
              disabled={isSecondaryButtonDisabled}
              className="btn-secondary btn-lg me-2"
              context={t(secondaryButtonText)}
            />
          )}
          {buttonText && (
            <ButtonCustom
              type="button"
              onClick={onButtonClick}
              disabled={isPrimaryButtonDisabled}
              className="btn-primary btn-lg"
              context={t(buttonText)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DynamicModal;
