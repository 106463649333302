import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useToast } from "../../common/contexts/useToast";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import MainHome from "../MainHome";
import { ReactComponent as InfoIcon } from "../../common/assats/icons/infoIcon.svg";
import ButtonCustom from "../common/Button";
import FormBuilder, { FormBuilderInterface } from "../common/FormBuilder";
import {
  createDevice,
  getDevicesDataForCreation,
  updateDevice,
} from "../../common/api/apiCalls";
import { useQuery } from "@tanstack/react-query";
import {
  DevicesCreateInterface,
  DeviceUpdateInterface,
} from "../../common/interfaces/Devices.interface";
import deviceValidationSchema from "../../common/validations/DeviceValidationSchemas";

const constDevicesCreateConfig: FormBuilderInterface[] = [
  {
    name: "deviceSerialNumber",
    label: "devicesPage.create.deviceSerialNumber",
    type: "text",
    required: true,
    translateOffset: false,
    translateLabel: true,
  },
  {
    name: "relatedSite",
    label: "general.filters.relatedSite",
    type: "dropdown",
    optionsArray: [],
    required: true,
    translateOffset: false,
    translateLabel: true,
  },
  {
    name: "relatedSiteAdmin",
    label: "devicesPage.filters.relatedSiteAdmin",
    type: "dropdown",
    optionsArray: [],
    required: true,
    translateOffset: false,
    translateLabel: true,
  },
  {
    name: "fwVersionShort",
    label: "devicesPage.create.fwVersionShort",
    type: "dropdown",
    optionsArray: [],
    required: true,
    translateOffset: false,
    translateLabel: true,
  },
  {
    name: "swVersionShort",
    label: "devicesPage.create.swVersionShort",
    type: "dropdown",
    optionsArray: [],
    required: true,
    translateOffset: false,
    translateLabel: true,
  },
  {
    name: "hwId",
    label: "devicesPage.create.hwId",
    type: "text",
    required: true,
    translateOffset: false,
    translateLabel: true,
  },
];

const DevicesCreate = () => {
  const { t } = useTranslation();
  const { showToast } = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const deviceLocationState = location.state || null;
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const [devicesCreateConfig, setDevicesCreateConfig] = useState<
    FormBuilderInterface[]
  >([]);

  const [deviceData, setDeviceData] = useState<DevicesCreateInterface>({
    deviceSerialNumber: '',
    relatedSite: "",
    relatedSiteAdmin: "",
    fwVersionShort: "",
    swVersionShort: "",
    hwId: "",
  });
  const [deviceId, setDeviceId] = useState<string>("");
  const [usersForSite, setUsersForSite] = useState<string[]>([]);


  const {
    data: response,
    isPending,
    error,
  } = useQuery({
    queryKey: [],
    queryFn: () => getDevicesDataForCreation(),
  });

  const setStateFields = () => {
    if (!deviceLocationState || !deviceLocationState?.id) {
      return;
    } else if (deviceLocationState !== null) {
      setIsEdit(true);
    }
    setDeviceId(deviceLocationState?.id);
    setDeviceData({
      deviceSerialNumber: deviceLocationState.deviceSerialNumber,
      relatedSite: deviceLocationState.relatedSite || "",
      relatedSiteAdmin: deviceLocationState.relatedSiteAdmin,
      fwVersionShort: deviceLocationState.fw_version,
      swVersionShort: deviceLocationState.sw_version,
      hwId: deviceLocationState.hwId,
    });
  };

  useEffect(() => {
    updateConfigData();
  }, [response, response?.success]);

  useEffect(() => {
    if (deviceLocationState?.relatedSiteAdmin && usersForSite.length > 0) {
      setDeviceData((prevData) => ({
        ...prevData,
        relatedSiteAdmin: usersForSite.includes(deviceLocationState.relatedSiteAdmin)
          ? deviceLocationState.relatedSiteAdmin
          : "",
      }));
    }
  }, [usersForSite, deviceLocationState]);


  const handleInputChange = (name: string, value: any) => {
    if (name === "relatedSite") {
      updateRelatedSiteAdmins(value, response.data.sites);
      setDeviceData({
        ...deviceData,
        relatedSite: value,
        relatedSiteAdmin: "",
      });
    } else {
      setDeviceData({ ...deviceData, [name]: value });
    }
  };

  const updateRelatedSiteAdmins = (siteName: string, sitesData: any[]) => {
    const selectedSite = sitesData?.find((site: any) => site.name === siteName);

    if (selectedSite?.id || selectedSite?.name) {
      const usersForSiteArr = selectedSite.users.map((user: any) => user.name);
      setUsersForSite(usersForSiteArr);
      setDevicesCreateConfig((prevConfig) =>
        prevConfig.map((field) =>
          field.name === "relatedSiteAdmin"
            ? { ...field, optionsArray: usersForSiteArr }
            : field
        )
      );
    } else {
      setDevicesCreateConfig((prevConfig) =>
        prevConfig.map((field) =>
          field.name === "relatedSiteAdmin"
            ? { ...field, optionsArray: [] }
            : field
        )
      );
    }
  };

  const updateConfigData = () => {
    if (response && response.success) {
      setDevicesCreateConfig([
        ...constDevicesCreateConfig.map((field) => {
          if (field.name === "relatedSite") {
            return {
              ...field,
              optionsArray: response?.data?.sites?.map(
                (site: any) => site.name
              ),
            };
          } else if (field.name === "relatedSiteAdmin") {
            return {
              ...field,
              optionsArray: response?.data?.users?.map(
                (user: any) => user.name
              ),
            };
          } else if (field.name === "fwVersionShort") {
            return {
              ...field,
              optionsArray: response?.data?.fota?.firmwareVersion.map(
                (version: any) => version.version,
              ),
              isDisabled: !!deviceLocationState?.id
            };
          } else if (field.name === "swVersionShort") {
            return {
              ...field,
              optionsArray: response?.data?.fota?.softwareVersion.map(
                (version: any) => version.version,
              ),
              isDisabled: !!deviceLocationState?.id
            };
          }
          return field;
        }),
      ]);
    }
    if (deviceLocationState !== null && deviceLocationState?.relatedSite) {
      updateRelatedSiteAdmins(
        deviceLocationState?.relatedSite,
        response?.data?.sites
      );
    }

    setStateFields();
  };

  const handleSubmit = async () => {
    try {

      await deviceValidationSchema.validate(deviceData, { abortEarly: false });
      const data: DevicesCreateInterface = {
        deviceSerialNumber: deviceData.deviceSerialNumber,
        relatedSite: deviceData.relatedSite,
        relatedSiteAdmin: deviceData.relatedSiteAdmin,
        fwVersionShort: deviceData.fwVersionShort,
        swVersionShort: deviceData.swVersionShort,
        hwId: deviceData.hwId,
      };
      let res = null;

      if (isEdit) {
        const updateDeviceData: DeviceUpdateInterface = {
          ...data,
          deviceId: deviceId,
        };
        res = await updateDevice(updateDeviceData);
      } else {
        res = await createDevice(data);
      }
      if (res.success) {
        showToast(
          "success",
          "Success",
          isEdit
            ? t("devicesPage.create.updateSuccess")
            : t("devicesPage.create.createSuccess")
        );
        navigate("/Home/Devices");
      } else {
        showToast("error", "Error", t(res.data.errorMessage));
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const validationErrors: { [key: string]: string } = {};
        err.inner.forEach((error) => {
          if (error.path) {
            validationErrors[error.path] = error.message;
          }
        });
        setErrors(validationErrors);
      }
    }
  };

  return (
    <MainHome>
      <div className="page-container w-100 h-100 p-5 border rounded background-primary-white">
        <div className="container pr-5 pl-5 h-100 d-flex flex-column">
          <div className="mb-4">
            <h3 className="fw-bold">
              {isEdit
                ? t("devicesPage.create.editTitle")
                : t("devicesPage.create.title")}
            </h3>
            <hr className="my-4" />
            <div className="ms-2 d-flex align-items-end">
              <InfoIcon />
              <h4 className="ms-2">
                {t("devicesPage.create.deviceInformation")}
              </h4>
            </div>
          </div>
          <div className="flex-grow-1">
            <FormBuilder
              fields={devicesCreateConfig}
              handleInputChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
              values={deviceData}
              showErrors={true}
              errors={errors}
              translateErrors={true}
            />
          </div>
          <hr className="my-4" />
          <div className="d-flex justify-content-end gap-2">
            <ButtonCustom
              type="button"
              onClick={() => navigate("/Home/Devices")}
              className="btn-secondary btn-lg"
              context={t("general.buttons.cancel")}
            />
            <ButtonCustom
              type="button"
              onClick={handleSubmit}
              className=" btn-primary btn-lg"
              context={
                isEdit
                  ? t("general.buttons.save")
                  : t("devicesPage.create.saveDevice")
              }
            />
          </div>
        </div>
      </div>
    </MainHome>
  );
};

export default DevicesCreate;
