import { ClinicalInfoInterface, DemographicInfoInterface } from "../../../types/PatientData.interface";

const patientDataTemplate: ClinicalInfoInterface & DemographicInfoInterface = {
  // ClinicalInfoInterface fields
  isSymptomatic: undefined, // Required boolean
  symptomsType: undefined, // Optional, 'Acute' | 'Chronic' | undefined
  chestPain: undefined, // Optional boolean
  chestPainType: undefined, // Optional, 'Typical' | 'Atypical' | undefined
  shortnessOfBreath: undefined, // Optional boolean
  shoulderOrArmPain: undefined, // Optional boolean
  jawPain: undefined, // Optional boolean
  dizzinessOrPresyncope: undefined, // Optional boolean
  nausea: undefined, // Optional boolean
  smokingStatus: undefined, // Optional, string enum
  takingOtherDrugs: "", // Optional boolean
  totalCholesterol: undefined, // Optional number
  ldl: undefined, // Optional number
  triglycerides: undefined, // Optional number
  bpSystolic: undefined, // Optional number
  bpDiastolic: undefined, // Optional number
  sugarLevel: undefined, // Optional number
  copd: undefined, // Optional boolean
  arrhythmiasOrConductionAbnormality: undefined, // Optional boolean
  peripheralArterialDisease: undefined, // Optional boolean
  familyHistoryCardiovascularEvents: undefined, // Optional boolean
  cerebrovascularDisease: undefined, // Optional boolean
  strokeOrTiaOrRIND: undefined, // Optional boolean
  chronicHeartFailure: undefined, // Optional boolean
  lvefLessThan30: undefined, // Optional boolean
  previousCardiacEvent: undefined, // Optional boolean
  hemodynamicInstability: undefined, // Optional boolean
  pulmonaryEdema: undefined, // Optional boolean
  anemia: undefined, // Optional boolean
  height: undefined, // Optional number
  weight: undefined, // Optional number
  bmi: undefined, // Optional number
  otherCoMorbidities: undefined, // Optional string
  notes: undefined, // Optional string
  lastVisit:  undefined, // Optional string
  honorific: undefined, // Required string enum
  firstName: '', // Required string
  middleName: '', // Required string
  identityNumber: '', // Required string
  lastName: '', // Required string
  birthday: '', // Required string
  gender: '', // Required string enum
  phoneNumber: '', // Required string
  email: '', // Required string
  state: '', // Required string
  address: '', // Required string
  city: '', // Required string
  country: '', // Required string
  zip: '', // Required string
  maritalStatus: '', // Required string enum
  race: '', // Required string enum
}

  export default patientDataTemplate;