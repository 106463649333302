import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import "../../styles/components/SitesPage.scss";
import "../../styles/components/General.scss";
import TablePage from "../genericTable/TablePage";
import { useTranslation } from "react-i18next";
import { TableCard } from "../../common/types/tableCardType";
import { ReactComponent as TotalExamIcon } from "../../common/assats/homeScreens/cardsIcons/beforeExam.svg";
import { ReactComponent as ActiveSitesIcon } from "../../common/assats/homeScreens/cardsIcons/valid.svg";
import { ReactComponent as NonActiveSitesIcon } from "../../common/assats/homeScreens/cardsIcons/cross.svg";
import { exportData, getSitesData, sitesToggleAction } from "../../common/api/apiCalls";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Filter from "../common/Filter/Filter";
import { deleteSite } from "../../common/api/apiCalls";
import { useToast } from "../../common/contexts/useToast";
import { sysConfig } from "../../config";
import { siteInfoFields } from "./SiteInfoFields";
import { addressFormat } from "../../common/function/addressFormat";
import { useFilters } from "../../common/contexts/FiltersContext";
import { useDebouncedSearch } from "../../common/hooks/Search";
import { AxiosResponse } from "axios";
import { RouteEnum } from "../../common/consts/roles";
import useUserStore from "../../common/store/user.store";

type SortOptions =
  | "siteName"
  | "siteAdmin"
  | "status"
  | "device_sn"
  | "totalExams";

const sortKeysArray: SortOptions[] = [
  "siteName",
  "siteAdmin",
  "status",
  "device_sn",
  "totalExams",
];

const SitesPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { search, debouncedSearch, handleSearchChange } = useDebouncedSearch(
    sysConfig.DEBOUNCE_TIME
  );
  const [sortKey, setSortKey] = useState<string>("siteName");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const [siteCount, setSiteCount] = useState(0);
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const { showToast } = useToast();
  const [page, setPage] = useState(1);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const { activeFilters, clearFilters, handleCardFilters } = useFilters();
  const [sitesData, setSitesData] = useState<any[]>([]);
  const location = useLocation();
  const locationState = location.state;

  const { canAccessCRUD } = useUserStore();
  const user = useUserStore((state) => state.user);
  
  const sortResetRef = useRef(false); 

  const [cards, setCards] = useState<TableCard[]>([]);

  const [filtersObject, setFiltersObject] = useState<any>({
    siteStatus: {
      label: t("sitePage.filters.siteStatus"),
      options: [
        { name: t("sitePage.filters.active"), label: "Active" },
        { name: t("sitePage.filters.inactive"), label: "Inactive" },
      ],
    },
    deviceSN: {
      label: t("sitePage.filters.deviceSN"),
      options: [],
    },
    siteNames: {
      label: t("sitePage.filters.siteName"),
      options: []
    }
  });

  useEffect(() => {
    if (locationState?.filters?.key && locationState?.filters?.value) {
      handleCardFilters(locationState.filters.key, locationState.filters.value);
      resetPagination();
    }
  }, [location.pathname, sortKey, sortOrder, debouncedSearch]);


  useEffect(() => {
    setPage(1);
    setSitesData([]);
    setHasMore(true);
  }, [sortKey, sortOrder]);

  useEffect(() => {
    fetchData();
  }, [sortKey, sortOrder, activeFilters, debouncedSearch, page]);


  const handleSortChange = (key: string, order: "asc" | "desc") => {
    const isKeyChanged = sortKey !== key;
    const isOrderChanged = sortOrder !== order;
  
    if (!isKeyChanged && !isOrderChanged) return;
  
    sortResetRef.current = true;
    setSitesData([]);
    setHasMore(true);
    setSortKey(key);
    setSortOrder(order);
    setPage(1);
  };

  const fetchData = async () => {
    const response = await getSitesData({
      sortKey,
      sortOrder,
      filters: activeFilters,
      search: debouncedSearch,
      page,
      limit: 10,
    });

    if (response?.data?.length && response?.total) {
      const filteredData = filterData(response.data);
      if (page === 1) {
        setSitesData(filteredData);
      } else {
        setSitesData((prev) => [...prev, ...filteredData]);
      }
      setIsFetchingMore(false);

      if (response?.devicesSN && response?.siteNames) {
        setFiltersObject((prev: any) => ({
          ...prev,
          deviceSN: {
            label: t("sitePage.filters.deviceSN"),
            options: response?.devicesSN?.map((device: any) => ({
              name: device,
              label: device,
            })),
          },
          siteNames: {
            label: t("sitePage.filters.siteName"),
            options: response?.siteNames?.map((site: string) => ({
              name: site,
              label: site,
            })),
          },
        }));
      }
    } else if (response?.data?.length === 0) {
      setHasMore(false);
      setIsFetchingMore(false);
    }
    setSiteCount(response?.total);
    setCardsData(response?.activeSites, response?.nonActiveSites, response?.totalExams);
    sortResetRef.current = false;
  };



  const setCardsData = (
    activeSites: number,
    nonActiveSites: number,
    totalExams: number
  ) => {
    setCards([
      {
        headline: t("sitePage.cards.activeSites"),
        explanation: t("sitePage.cards.activeSitesExplain"),
        value: activeSites || 0,
        icon: ActiveSitesIcon,
        action: () => {
          handleCardFilters("siteStatus", "Active");
          resetPagination();
        },
      },
      {
        headline: t("sitePage.cards.nonActiveSites"),
        explanation: t("sitePage.cards.nonActiveSitesExplain"),
        value: nonActiveSites || 0,
        icon: NonActiveSitesIcon,
        action: () => {
          handleCardFilters("siteStatus", "Inactive");
          resetPagination();
        },
      },
      {
        headline: t("sitePage.cards.totalExams"),
        explanation: t("sitePage.cards.totalExamsExplain"),
        value: totalExams || 0,
        icon: TotalExamIcon,
        action: () => {
          handleCardFilters("totalExams", "total");
          resetPagination();
        },
      },
    ]);
  };

  const resetPagination = () => {
    setPage(1);
    setSitesData([]);
    setHasMore(true);
    setSelectedRows([]);
    setIsFetchingMore(false);
  };

  const navigateToCreateSite = () => {
    navigate("/Home/Sites/Create");
  };

  const toggleEditSite = (id: string) => {
    const editData = sitesData?.find((site: any) => site.tableProps?.id === id);

    const addressData = {
      address: editData.tableProps.additionalData?.address,
      city: editData.tableProps.additionalData?.city,
      state: editData.tableProps.additionalData?.state,
      country: editData.tableProps.additionalData?.country,
      zipCode: editData.tableProps.additionalData?.zipCode,
    };

    const displayData = {
      siteName: editData.siteName,
      siteNumber: editData.tableProps.additionalData?.siteNumber,
      siteAdminFullName:
        editData.tableProps.additionalData?.siteAdmin?.fullName,
      siteAdminEmail: editData.tableProps.additionalData?.siteAdmin?.username,
      siteAdminPhone:
        editData.tableProps.additionalData?.siteAdmin?.userInfo.phoneNumber,
      siteStatus: editData.siteStatus,
      metrics: editData.tableProps?.additionalData?.metrics,
      address: addressFormat(addressData),
    };

    const flatData = {
      ...displayData,
      ...addressData,
      id,
    };
    navigate(`/Home/Sites/View/${id}`, {
      state: {
        displayData: siteInfoFields(displayData),
        editState: flatData,
        informationData: {
          title: editData.siteName,
          status: editData.siteStatus,
          pathToEdit: `/Home/Sites/Edit/${id}`,
          editPermission: RouteEnum.SitesPageCreate,
        },
      },
    });
  };

  const onEditClick = (row: any) => {
    const canEdit = canAccessCRUD(RouteEnum.SitesPageEdit, user?.role);
    const editData = sitesData?.find((site: any) => site.tableProps?.id === row.tableProps?.id);
    if (!canEdit || !editData) return;

    const addressData = {
      address: editData.tableProps.additionalData?.address,
      city: editData.tableProps.additionalData?.city,
      state: editData.tableProps.additionalData?.state,
      country: editData.tableProps.additionalData?.country,
      zipCode: editData.tableProps.additionalData?.zipCode,
    };

    const displayData = {
      siteName: editData.siteName,
      siteNumber: editData.tableProps.additionalData?.siteNumber,
      siteAdminFullName:
        editData.tableProps.additionalData?.siteAdmin?.fullName,
      siteAdminEmail: editData.tableProps.additionalData?.siteAdmin?.username,
      siteAdminPhone:
        editData.tableProps.additionalData?.siteAdmin?.userInfo.phoneNumber,
      siteStatus: editData.siteStatus,
      metrics: editData.tableProps?.additionalData?.metrics,
      address: addressFormat(addressData),
    };
    navigate(`/Home/Sites/Edit/${row.tableProps.id}`, {
      state: {
        ...displayData,
        ...addressData,
        id: row.tableProps.id,
        edit: true
      }
    });
  }

  const onToggleActionClick = async (row: any) => {
    const id = row?.tableProps?.id as string;
    const site = sitesData.find((site) => site.tableProps.id === id);
    if (!id || !site) return;
    const res = await sitesToggleAction([id]);
    if (res.success) {
      resetPagination();
      fetchData();
    } else {
      showToast("error", t("general.toast.error"), t("general.errors.toggleActionError"));
    }
  }

  const handleDeleteOnClick = async (row: any) => {
    const canDelete = canAccessCRUD(RouteEnum.PatientEdit, user?.role)
    if (!canDelete) return;
    if (row?.tableProps?.id) {
      const response = await deleteSite([row?.tableProps?.id as string]);
      if (response.success) {
        resetPagination();
        fetchData();
      } else if (response?.data?.errorType) {
        showToast(
          "info",
          t("general.delete.title"),
          t(response?.data?.errorMessage || "general.delete.error")
        );
        resetPagination();
        fetchData();
      } else {
        showToast(
          "error",
          t("general.delete.title"),
          t(response?.data?.errorMessage || "general.delete.error")
        );
      }
    }
  }

  const filterData = (data: any) => {
    return data.map((site: any) => ({
      siteName: site.siteName,
      siteAdmin: site.siteAdmin?.fullName,
      siteStatus: site?.siteStatus || "Not-active",
      device_sn: site?.device_sn || "N/A",
      totalExams: site?.totalExams || 0,
      tableProps: {
        id: site.id,
        selected: false,
        additionalData: {
          address: site.address,
          city: site.city,
          state: site.state,
          country: site.country,
          zipCode: site.zipCode,
          siteNumber: site.siteNumber,
          metrics: site.metrics,
          siteAdmin: site.siteAdmin,
        },
      },
    }));
  };

  const handleCheckBoxClick = (updatedRow: any) => {
    const rowId = updatedRow.tableProps.id;

    const updatedRows = sitesData.map((row) => {
      if (row.tableProps.id === rowId) {
        return {
          ...row,
          tableProps: {
            ...row.tableProps,
            selected: updatedRow.tableProps.selected,
          },
        };
      }
      return row;
    });

    setSitesData(updatedRows);

    if (updatedRow.tableProps.selected) {
      setSelectedRows([...selectedRows, updatedRow]);
    } else {
      setSelectedRows(
        selectedRows.filter((row) => row.tableProps.id !== rowId)
      );
    }
  };

  const exportSites = async (type: string) => {
    try {
      const params = {
        type: "sites",
        formats: type.toLowerCase(),
        sortKey,
        sortOrder,
        filters: activeFilters,
        search: debouncedSearch,
      };

      const response = await exportData("export", params);

      if (response.success && response.data) {
        const axiosResponse = response.data as AxiosResponse<any, any>;

        const contentDisposition = axiosResponse.headers["content-disposition"];
        const contentType = axiosResponse.headers["content-type"];
        const fileName = contentDisposition
          ? contentDisposition.split("filename=")[1]?.replace(/['"]/g, "")
          : contentType.includes("json")
            ? "export.json"
            : contentType.includes("spreadsheet")
              ? "export.xlsx"
              : "export.zip";

        const blob = new Blob([axiosResponse.data], { type: contentType });

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();

        link.remove();
        window.URL.revokeObjectURL(url);
      } else {
        showToast(
          "error",
          t("general.toast.error"),
          t("general.export.exportGeneralError")
        );
      }
    } catch (error) {
      showToast(
        "error",
        t("general.toast.error"),
        t("general.export.exportGeneralError")
      );
    }
  };

  const searchChangeHandler = (str: string) => {
    handleSearchChange(str);
    resetPagination();
  };

  const handleFetchMore = () => {
    if (!isFetchingMore && hasMore && !sortResetRef.current) {
      setIsFetchingMore(true);
      setPage((prev) => prev + 1);
    }
  };

  const handleCheckAll = (checked: boolean) => {
    const updatedRows = sitesData.map((row) => {
      return {
        ...row,
        tableProps: {
          ...row.tableProps,
          selected: checked,
        },
      };
    });

    setSitesData(updatedRows);
    setSelectedRows(checked ? updatedRows : []);
  };

  return (
    <div className="table-page-main-container">
      <Filter
        isOpen={isFilterOpen}
        filtersObject={filtersObject}
        handleFilterClose={() => {
          setIsFilterOpen(false);
        }}
        filterTrigger={resetPagination}
      />
      {
        <TablePage
          hasMore={hasMore}
          isFetchingMore={isFetchingMore}
          fetchMore={handleFetchMore}
          viewAllAction={() => {
            clearFilters();
            resetPagination();
          }}
          tableHeadline={t(`sitePage.sitesList`)}
          headerHeadline={t("sitePage.sites")}
          totalCount={siteCount}
          cards={cards}
          createPath="SitesPageCreate"
          sortKeysArray={
            sortKeysArray?.map((key) => {
              return { name: t(`sitePage.sort.${key}`), val: key };
            }) || []
          }
          setSortKey={(key) => handleSortChange(key, sortOrder)}
          setSortOrder={(order) => handleSortChange(sortKey, order)}
          sortKey={sortKey}
          tableData={sitesData}
          sortOrder={sortOrder}
          searchValue={search}
          setSearchValue={searchChangeHandler}
          handleCheckAll={(e) => {
            handleCheckAll(e);
          }}
          searchPlaceholder={t("sitePage.siteSearch")}
          onCheckBoxClick={handleCheckBoxClick}
          filterAction={() => {
            setIsFilterOpen(!isFilterOpen);
          }}
          buttonText={t("sitePage.addSite")}
          buttonAction={navigateToCreateSite}
          onRowClick={(row) => {
            toggleEditSite(row.tableProps.id);
          }}
          exportAction={exportSites}
          isExport={true}
          canEdit={canAccessCRUD(RouteEnum.SitesPageEdit, user?.role)}
          canToggleActive={canAccessCRUD(RouteEnum.SiteToggleAction, user?.role)}
          onEditClick={(row: any) => { onEditClick(row) }}
          onToggleActionClick={(row: any) => { onToggleActionClick(row) }}
          onDeleteClick={(row: any) => { handleDeleteOnClick(row) }}
        />
      }
    </div>
  );
};

export default SitesPage;
