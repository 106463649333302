import React from "react";
import "../../../styles/components/CustomFileInput.scss";

interface CustomFileInputProps {
  label?: string;
  name: string;
  onChange: (file: File | null) => void;
  error?: string;
  required?: boolean;
  disabled?: boolean;
}

const CustomFileInput: React.FC<CustomFileInputProps> = ({
  label,
  name,
  onChange,
  error,
  required,
  disabled,
}) => {
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    onChange(file);
  };

  return (
    <div className={`custom-file-input-container ${error ? "error" : ""}`}>
      {
        label && (
          <label htmlFor={name} className="custom-file-label">
            {label}
            {required && <span className="required">*</span>}
          </label>
        )
      }
      <input
        type="file"
        id={name}
        name={name}
        className="custom-file-input"
        onChange={handleFileChange}
        disabled={disabled}
      />
      {error && <div className="error-text">{error}</div>}
    </div>
  );
};

export default CustomFileInput;