import React, { useEffect } from "react";
import "../../styles/components/SitesPage.scss";
import "../../styles/components/General.scss";
import { useTranslation } from "react-i18next";
import FormBuilder, { FormBuilderInterface } from "../common/FormBuilder";
import { isDisabled } from "@testing-library/user-event/dist/utils";

const siteInformationCreateConfig: FormBuilderInterface[] = [
  {
    name: "siteName",
    label: "sitePage.siteCreate.siteName",
    type: "text",
    required: true,
    translateOffset: false,
    translateLabel: true,
  },
  {
    name: "siteNumber",
    label: "sitePage.siteCreate.siteNumber",
    type: "number",
    required: true,
    translateOffset: false,
    translateLabel: true,
  },
  {
    name: "siteAdminFullName",
    label: "sitePage.siteCreate.siteAdminFullName",
    type: "text",
    required: true,
    translateOffset: false,
    translateLabel: true,
  },
  {
    name: "siteAdminEmail",
    label: "sitePage.siteCreate.siteAdminEmail",
    type: "text",
    required: true,
    translateOffset: false,
    translateLabel: true,
  },
  {
    name: "siteAdminPhone",
    label: "sitePage.siteCreate.siteAdminPhone",
    type: "text",
    required: true,
    translateOffset: false,
    translateLabel: true,
  },
  {
    name: "metrics",
    label: "sitePage.siteCreate.metrics",
    type: "dropdown",
    optionsArray: [
      "sitePage.siteCreate.metricsImperial",
      "sitePage.siteCreate.metricsMetric",
    ],
    required: false,
    translateOffset: false,
    translateLabel: true,
    translateOptions: true,
  },
  {
    name: "siteStatus",
    label: "sitePage.siteCreate.siteStatus",
    type: "dropdown",
    optionsArray: ["Active", "Inactive"],
    required: false,
    translateOffset: false,
    translateLabel: true,
  },
];

interface SiteInformationCreateProps {
  values: { [key: string]: any };
  handleInputChange: (name: string, value: any) => void;
  errors: { [key: string]: string };
}

const SiteInformationCreate = ({
  values,
  handleInputChange,
  errors,
}: SiteInformationCreateProps) => {


  return (
    <div className="">
      <FormBuilder
        fields={siteInformationCreateConfig}
        handleInputChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleInputChange(e.target.name, e.target.value)
        }
        values={values}
        showErrors={true}
        errors={errors}
        translateErrors={true}
      />
    </div>
  );
};

export default SiteInformationCreate;
