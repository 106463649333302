import React, {
  createContext,
  useContext,
  useMemo,
  useState,
  ReactNode,
  useEffect,
  useLayoutEffect,
} from "react";

interface FiltersContextProps {
  activeFilters: { [key: string]: string[] };
  filtersCount: number;
  handleFilters: (
    filterName: string,
    optionName: string,
    isSelected: boolean,
    isDeletePrevious?: boolean
  ) => void;
  clearFilters: () => void;
  handleCardFilters: (filterName: string, optionName: string) => void;
}

const FiltersContext = createContext<FiltersContextProps | undefined>(
  undefined
);

export const FiltersProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [activeFilters, setActiveFilters] = useState<{
    [key: string]: Array<string>;
  }>({});

  const handleFilters = (
    filterName: string,
    optionName: string,
    isSelected: boolean,
    isDeletePrevious?: boolean
  ) => {
    setActiveFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };

      if (isDeletePrevious) {
        updatedFilters[filterName] = [];
      }

      if (!updatedFilters[filterName]) {
        updatedFilters[filterName] = [];
      }

      if (isSelected) {
        if (updatedFilters[filterName].includes(optionName)) {
          return updatedFilters;
        }
        updatedFilters[filterName].push(optionName);
      } else {
        updatedFilters[filterName] = updatedFilters[filterName].filter(
          (option: string) => option !== optionName
        );
        if (!updatedFilters[filterName].length) {
          delete updatedFilters[filterName];
        }
      }
      return updatedFilters;
    });
  };

  const clearFilters = () => {
    setActiveFilters({});
  };

  const handleCardFilters = (filterName: string, optionName: string) => {
    clearFilters();
    handleFilters(filterName, optionName, true);
  };

  const filtersCount = useMemo(() => {
    return Object.values(activeFilters).reduce((totalCount, filterOptions) => {
      return totalCount + filterOptions.length;
    }, 0);
  }, [activeFilters]);

  return (
    <FiltersContext.Provider
      value={{
        activeFilters,
        filtersCount,
        handleFilters,
        clearFilters,
        handleCardFilters,
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};

export const useFilters = () => {
  const context = useContext(FiltersContext);
  if (context === undefined) {
    throw new Error("useFilters must be used within a FiltersProvider");
  }
  return context;
};
