import React, { FC } from "react";
import "../styles/loginStyles/halfScreenLogo.scss";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Image,
  Dropdown,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { logos } from "../common/assats";
import { ReactComponent as AcculineSmallLogo } from "../common/assats/homeScreens/acculine_small_logo.svg";
import { ReactComponent as LangVector } from "../common/assats/icons/Vector.svg";
import { sysConfig } from "../config";
interface HalfScreenLogo {
  children: React.ReactNode;
}
const HalfScreenLogo: FC<HalfScreenLogo> = ({ children }) => {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  return (
    <Container fluid className="HalfScreenLogo-main-container">
      <Row className="full-width-height no-gutters">
        <Col className="left-side flex-center">
          <Col className="inner-left-side flex-center">
            <Col className="logo-wrapper">
              <Image width={"10%"} src={logos.coraLogo} />
            </Col>

            <Col className="children-wrapper flex-center">{children}</Col>
            <Col className="footer-wrapper ">
              <Col className="powered-by-wrapper ">
                <Form.Label className="">
                  {t("half_screen.POWERED_BY")}
                </Form.Label>
                <AcculineSmallLogo className=" acc-logo-small" />
              </Col>
              <Col className="terms-and-privacy">
                <a href="/terms" target="_blank" rel="noopener noreferrer">
                  <Form.Label className="global-sub-label global-font-weight-600 text-primary cursor-pointer">
                    {t("half_screen.TERMS")}
                  </Form.Label>
                </a>
                <a href="/privacy" target="_blank" rel="noopener noreferrer">
                  <Form.Label className="global-sub-label global-font-weight-600 text-primary cursor-pointer">
                    {t("half_screen.PRIVACY")}
                  </Form.Label>
                </a>
              </Col>
            </Col>
          </Col>
        </Col>

        <Col className="right-side d-flex justify-center align-center mt-3">
          <div className="background-image">
            <Dropdown>
              <Dropdown.Toggle className="lang-dropdown">
                <LangVector />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {sysConfig.LANGUAGE_OPTIONS.map((option) => (
                  <Dropdown.Item
                    key={option.value}
                    onClick={() => changeLanguage(option.value)}
                  >
                    {option.label}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default HalfScreenLogo;
