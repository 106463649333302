import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../../styles/components/DatePicker.scss";
import { menuIcons } from "../../../common/assats";

interface CustomDatePickerProps {
  name: string;
  label?: string;
  value: Date | null;
  handleFocus: (event: React.FocusEvent<HTMLInputElement>) => void;
  handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  handleInputChange: ({
    target: { name, value },
  }: {
    target: { name: string; value: Date | null };
  }) => void;
  placeHolder?: string;
  required?: boolean;
  error?: string;
  showErrors?: boolean;
  disabled?: boolean;
  startIcon?: React.ReactNode;
  isArrow?: boolean;
  showYearDropdown?: boolean;
  scrollableYearDropdown?: boolean;
  minDate?: Date;
  maxDate?: Date;
}

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  name,
  label,
  value,
  handleFocus,
  handleInputChange,
  handleBlur,
  required,
  error,
  showErrors,
  disabled,
  placeHolder,
  startIcon,
  isArrow,
  showYearDropdown = true,
  minDate = new Date("1900-01-01"),
  maxDate, 
}) => {
  const defaultMaxDate = new Date();
  defaultMaxDate.setFullYear(defaultMaxDate.getFullYear() - 18);

  const createCustomEvent = (value: Date | null) => {
    handleInputChange({ target: { name: name, value: value } });
  };

  const yearRange = defaultMaxDate.getFullYear() - minDate.getFullYear() + 1;

  return (
    <div className="custom-date-picker-container-component">
      <div className="custom-date-picker">
        {label && (
          <label
            htmlFor={name}
            className={`custom-date-picker-label ${
              required ? "required-label" : ""
            }`}
          >
            {label}
            {required && <span className="required-asterisk">*</span>}
          </label>
        )}
        {startIcon && <div className="date-picker-icon ">{startIcon}</div>}
        <DatePicker
          selected={value}
          onChange={(date) => createCustomEvent(date)}
          dateFormat="MMM d, yyyy"
          className={`form-control pointer ${
            showErrors && error ? "is-invalid" : ""
          }`}
          onFocus={handleFocus}
          placeholderText={placeHolder}
          name={name}
          onBlur={handleBlur}
          id={name}
          monthsShown={1}
          autoComplete="off"
          disabled={disabled}
          showYearDropdown={showYearDropdown}
          scrollableYearDropdown={true}
          minDate={minDate}
          maxDate={maxDate || defaultMaxDate}
          yearDropdownItemNumber={yearRange}
        />
        {isArrow && (
          <img className="date-picker-select-image " src={menuIcons.arrow} />
        )}
      </div>

      {showErrors && error && <div className="invalid-feedback">{error}</div>}
    </div>
  );
};

export default CustomDatePicker;
