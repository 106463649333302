import "../styles/components/DashboardPage.scss";
import { useEffect, useMemo, useState } from "react";
import TableHeader from "./genericTable/TableHeader";
import { TableCard } from "../common/types/tableCardType";
import { ReactComponent as DeviceIcon } from "../common/assats/homeScreens/cardsIcons/devices.svg";
import { ReactComponent as ActiveDeviceIcon } from "../common/assats/homeScreens/cardsIcons/activeDevices.svg";
import { ReactComponent as ExamsIcon } from "../common/assats/homeScreens/cardsIcons/exams.svg";
import GraphsViewer from "./dashboard/GraphsViewer";
import { getAllBranchPatientsWithClinical } from "../common/api/apiCalls";
import { useTranslation } from "react-i18next";
import Filter from "./common/Filter/Filter";
import { useFilters } from "../common/contexts/FiltersContext";
import TableFilters from "./genericTable/TableFilters";
import { useToast } from "../common/contexts/useToast";
import { useNavigate } from "react-router-dom";
import CustomDoubleDatePicker from "./common/DatePicker/DoubleDatePicker";
import useUserStore from "../common/store/user.store";
import { roles } from "../common/consts/roles";

const DashboardPage = () => {
  const { t } = useTranslation();
  const { showToast } = useToast();
  const navigate = useNavigate();
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const { activeFilters, handleFilters, clearFilters, handleCardFilters } =
    useFilters();

  useEffect(() => {
    fetchData();
  }, [activeFilters]);

  const [graphData, setGraphData] = useState<any>([]);
  const [sites, setSites] = useState<any>([]);
  const [cards, setCards] = useState<TableCard[]>([]);
  const [dateLable, setDateLable] = useState<string>(t("dashboard.allDates"));
  const [isLableChanged, setIsLableChanged] = useState<boolean>(false);
  const user = useUserStore((state) => state.user);


  const isUserRoleSiteAdmin = useMemo(() => {
    return user?.role === roles.SiteAdmin;
  }, [user]);


  const fetchData = async () => {
    const response = await getAllBranchPatientsWithClinical(activeFilters);
    if (response.data && response.success) {
      setGraphData(response.data.graphData);
      // setPatients(response.data.patients);
      setSites(response.data.sites);
      setCardsData(
        response.data?.counts?.activeDevices,
        response.data?.counts?.deviceCount,
        response.data?.counts?.totalExams
      );
    } else {
      showToast(
        "error",
        t("general.toast.error"),
        t("general.errors.generalError")
      );
    }
  };

  const setCardsData = (
    activeDevices: number,
    deviceCount: number,
    totalExams: number
  ) => {
    setCards([
      {
        headline: t("dashboard.cards.totalDevices"),
        explanation: t("patientsList.cards.BeforeExamExplain"),
        icon: DeviceIcon,
        value: deviceCount,
        action: () => {
          navigateToDevicesWithFilters();
        },
      },
      {
        headline: t("dashboard.cards.totalActiveDevices"),
        explanation: t("patientsList.cards.afterExamExplain"),
        icon: ActiveDeviceIcon,
        value: activeDevices,
        action: () => {
          navigateToDevicesWithFilters("status", "Active");
        },
      },
      {
        headline: t("dashboard.cards.totalExams"),
        explanation: t("patientsList.cards.afterConsultationExplain"),
        icon: ExamsIcon,
        value: totalExams,
      },
    ]);
  };

  const navigateToDevicesWithFilters = (
    filterName?: string,
    optionName?: string
  ) => {
    if (filterName && optionName) {
      navigate("/Home/Devices", {
        state: {
          filters: {
            key: filterName,
            value: optionName,
          },
        },
      });
      return;
    }
    navigate("/Home/Devices");
  };

  const filtersObject = {
    siteStatus: {
      label: t("sitePage.sitesList"),
      options: isUserRoleSiteAdmin ? [] : sites?.map((site: string) => ({
        name: site,
        label: site,
      })),
    },
  };

  const cardsConfig: Array<{
    property: string;
    type: "circle" | "bar";
    title: string;
    barCount?: number;
  }> = [
      {
        property: "smokingStatus",
        type: "circle",
        title: t("dashboard.smoking"),
      },
      { property: "race", type: "circle", title: t("dashboard.race") },
      {
        property: "isSymptomatic",
        type: "circle",
        title: t("dashboard.symptomatic"),
      },
      { property: "gender", type: "circle", title: t("dashboard.gender") },
      { property: "BMI", type: "bar", title: t("dashboard.bmi"), barCount: 5 },
      { property: "age", type: "bar", title: t("dashboard.age"), barCount: 7 },
      {
        property: "triglycerides",
        type: "bar",
        title: t("dashboard.triglycerides"),
        barCount: 4,
      },
      {
        property: "bpSystolic",
        type: "bar",
        title: t("dashboard.bpSystolic"),
        barCount: 4,
      },
      {
        property: "totalCholesterol",
        type: "bar",
        title: t("dashboard.totalCholesterol"),
        barCount: 3,
      },
      {
        property: "sugarLevel",
        type: "bar",
        title: t("dashboard.glucose"),
        barCount: 3,
      },
    ];

  const clearFiltersDate = () => {
    fetchData();
    setIsLableChanged(false);
    setDateLable(t("dashboard.allDates"));
  }

  const handleDateFilters = (dates: [Date, Date]) => {
    const dateFilter = `${dates[0].toISOString()} &-& ${dates[1].toISOString()}`;
    handleFilters("dateFilter", dateFilter, true, true);
  }

  const dateSubmitHandler = (dates: [Date, Date]) => {
    handleDateFilters(dates);
    setIsLableChanged(true);
    setDateLable(`${dates[0].toLocaleDateString()} - ${dates[1].toLocaleDateString()}`);
  }

  return (
    <div className="dashboard-page-main-container">
      <div className="table-header">
        <TableHeader cards={cards} />
      </div>
      <div className="dashboard-filters pt-3 d-flex ">
        <div className="date-picker-container pt-2">
          <CustomDoubleDatePicker
            submitDateRange={dateSubmitHandler}
            label={dateLable}
            isLableChanged={isLableChanged}
          />
          <Filter
            isOpen={isFilterOpen}
            filtersObject={filtersObject}
            onClearFilters={clearFiltersDate}
            handleFilterClose={() => {
              setIsFilterOpen(false);
            }}
          />
        </div>
        <div className="sites-filter-container">
          <TableFilters
            isExport={true}
            exportAction={(type: string) => {
            }}
            filterAction={() => {
              setIsFilterOpen(!isFilterOpen);
            }}
          />
        </div>
      </div>
      <div className="graph-container">
        <GraphsViewer dataSet={graphData ?? []} cardsConfig={cardsConfig} />
      </div>
    </div>
  );
};

export default DashboardPage;
