import React, { FC, useEffect, useMemo, useState } from 'react'
import "../../styles/homePage/table/tableHeader.scss"
import { useTranslation } from 'react-i18next'
import TableHeaderCards from './TableHeaderCards'
import { TableCard, TableHeaderTabs } from '../../common/types/tableCardType'
import { ReactComponent as Arrow } from '../../common/assats/homeScreens/cardsIcons/arrow.svg';

export interface TableHeaderParams {
    headerHeadline?: string
    totalCount?: number
    cards: TableCard[]
    viewAllAction?: () => void,
    tabs?: TableHeaderTabs[],
    tableHeaderTitle?: string,
    customContainerClass?: string
};



const TableHeader: FC<TableHeaderParams> = ({
    headerHeadline,
    cards,
    totalCount,
    viewAllAction,
    tabs,
    tableHeaderTitle,
    customContainerClass
 }) => {

    const { t } = useTranslation()


    return (

        <div className={`table-header-main-container ${customContainerClass}`}>
            {headerHeadline ?
                <div className={`table-status-headline ${!totalCount ? 'flex-start' : ''}`}>
                    <div className='main-headline-container'>
                        <h2 className='main-headline'>{headerHeadline}</h2>
                    </div>
                    {totalCount ?
                        <div className='second-headline-container'>
                            <h4 className='total-text'>{t("table.header.total") + " " + headerHeadline} {totalCount}</h4>
                            <p className='view-all-text' onClick={viewAllAction}>{t("table.header.viewAll")} <Arrow /></p>

                        </div> : null
                    }

                </div> : null
            }
            <div className='patients-cards'>
                <TableHeaderCards cards={cards} tabs={tabs} tableHeaderTitle={tableHeaderTitle} />
            </div>
        </div>
    )
}

export default TableHeader