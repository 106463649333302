export const getErrorMessage = (error: any): { message: string; redirectPath?: string, errorType?:string } => {
    let errorMessage = "general.errors.generalError";
    let redirectPath: string | undefined = undefined;
    let errorType: string | undefined = undefined;

    if (!error) {
        return { message: errorMessage, redirectPath };
    }

    // Check if the error has a response and an error code
    if (error.response) {
        const { status, data } = error.response;
        let errorCode = data?.error || "UNKNOWN_ERROR";

        // Map error codes to user-friendly messages (i18n) and redirection paths
        const errorMessages: Record<string, { message: string; redirectPath?: string, errorType?:string }> = {
            // General errors
            "GE1": { message: "general.errors.generalError" },
            "GE2": { message: "general.errors.filledFieldsError" },
            "GE3": { message: "general.errors.uploadToS3Error" },
            "GE4": { message: "general.errors.missingParameters" },
            "GE5": { message: "general.errors.examCreationError" },
            "GE6": { message: "general.errors.fetchFromS3Error" },
            "GE7": { message: "general.errors.couldNotSendEmail" },
            "GE8": { message: "general.errors.couldNotSendEmailWithFile" },
            "GE9": { message: "general.errors.pdfBodyGenerationError" },
            "GE10": { message: "general.errors.invalidEmailList" },
            "GE11": { message: "general.errors.invalidEmailsForFile" },
            "GE12": { message: "general.errors.userInactive" },
            "GE13": { message: "general.errors.siteInactive" },
            "GE14":{ message: "general.errors.tooManyRequests" },
            "GE15": { message: "general.errors.userBlocked" },

            // Site creation errors
            "SN1": { message: "sitePage.siteCreate.errors.siteNameExists" },
            "SN2": { message: "sitePage.siteCreate.errors.sitesFetchError" },
            "SN3": { message: "sitePage.siteCreate.errors.siteNotFound" },
            // User section
            "U1": { message: "usersList.errors.userNotFound" },
            "U2": { message: "usersList.errors.userEmailExists" },
            "U3": { message: "usersList.errors.couldNotCreateUser" },
            "U4": { message: "usersList.errors.userPersonalIdExists" },
            "U5": { message: "usersList.errors.wrongOTP" },
            "U6": { message: "usersList.errors.wrongCredentials"  },
            "U7": { message: "usersList.errors.passwordChangeDoesntRequired", redirectPath: "/" },
            "U11": { message: "usersList.errors.userExistsWithProvidedData" }, 

            // Roles section
            "R1": { message: "sitePage.siteCreate.errors.rolesFetchError" },
            "R2": { message: "sitePage.siteCreate.errors.roleNotFound" },

            // Patient section
            "P1": { message: "patientsList.errors.patientNotFound" },
            "P2": { message: "patientsList.errors.patientEmailExists" },
            "P3": { message: "patientsList.errors.couldNotCreatePatient" },
            "P4": { message: "patientsList.errors.patientPersonalIdExists" },
            "PC4": { message: "patientsList.errors.cannotDeletePatientWithExams" },
            "PC5":{ message: "patientsList.errors.deletedPartialPatient", errorType:"info" },


            // EXPORT ERROR CODES:
            "EX1": { message: "general.errors.invalidFileType" },
            "EX2": { message: "general.errors.exportTypeError" },
            "EX3": { message: "general.errors.zipFileCreationError" },
            "EX4": { message: "general.errors.generalExportError" },


            // EXAM ERROR CODES:
            "E1": { message: "general.errors.examNotFound" },
            "E2": { message: "general.errors.examCreateError" },
            "E3": { message: "general.errors.examUpdateError" },


            // DEVICE ERROR CODES:
            "D1": { message: "general.errors.deviceNotFound" },
            "D2": { message: "general.errors.deviceSerialNumberExists" },
            "D3": { message: "general.errors.devicesFetchError" },
            "D4": { message: "general.errors.deviceUpdateError" },
            "D5": { message: "general.errors.deviceSiteAdminNotFound" },
        };

        const errorDetails = errorMessages[errorCode];
        if (errorDetails) {
            errorMessage = errorDetails.message;
            redirectPath = errorDetails.redirectPath;
            errorType = errorDetails.errorType;
        }
    }

    return { message: errorMessage, redirectPath, errorType };
};
