import {SitePropsGeneralInformation } from "../../common/interfaces/Sites.interface";
import { InfoFieldsBuilderInterface } from "../common/InfoFieldsBuilder";

export const siteInfoFields = (
  data: SitePropsGeneralInformation
): InfoFieldsBuilderInterface[] => [
  {
    title: "sitePage.siteCreate.siteAdminFullName",
    textArray: [data?.siteAdminFullName ?? ""],
    colWidth: "col-sm-3",
    translate: true,
  },
  {
    title: "sitePage.siteCreate.siteAdminEmail",
    textArray: [data?.siteAdminEmail ?? ""],
    colWidth: "col-sm-3",
    translate: true,
  },
  {
    title: "sitePage.siteCreate.siteAdminPhone",
    textArray: [data?.siteAdminPhone ?? ""],
    colWidth: "col-sm-3",
    translate: true,
  },
  {
    title: "sitePage.siteCreate.metrics",
    textArray: [data?.metrics ?? ""],
    colWidth: "col-sm-3",
    translate: true,
  },
  {
    title: "sitePage.siteCreate.address",
    textArray: [data?.address ?? ""],
    colWidth: "col-sm-3",
    translate: true,
  }
];
