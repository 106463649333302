// userStore.ts
import { create } from "zustand";
import { User } from "../consts/user";
import { getUserInfo } from "../api/apiCalls";
import { Role } from "../consts/roles";

interface UserStore {
  user: User | null | undefined;
  setUser: (user: User | null | undefined) => void;
  getUser: () => Promise<User | null | undefined>;
  updateUser: (newUserData: Partial<User>) => void;
  userPermissions: string[];
  canAccessCRUD: (operation: string, role?: Role) => boolean;
}

const useUserStore = create<UserStore>((set, get) => ({
  user: null,
  userPermissions: [],
  setUser: (user) => set({ user }),

  getUser: async () => {
    const u = await getUserInfo();
    set({
      user: u,
      userPermissions: u?.operations || [],
    });
    return u;
  },

  updateUser: (newUserData) => {
    set((state) => {
      const prevUser = state.user;
      if (!prevUser) return state;

      return {
        user: {
          ...prevUser,
          ...newUserData,
        },
      };
    });
  },

  canAccessCRUD: (operation: string, role?: Role): boolean => {
    if (!role) return false;
    const userOperations = get().userPermissions;

    return userOperations.includes(operation);
  },
}));

export default useUserStore;
