import { TableRowBase } from "../../types/Table.interface";

export enum FOTA {
    FIRMWARE = "firmware",
    SOFTWARE = "software"
}

export interface IFota {
    id: string;
    type: string;
    version: string;
    downloadURL: string;
    releaseDate: string;
}


export interface FOTAType {
    type: FOTA;
}


export interface IFotaTableData extends Partial<IFota>,TableRowBase {
    versionName: string;
    versionDate: string;
    type: string;
}