import React, { useEffect, useRef } from "react";
import "../../styles/homePage/table/tableRow.scss";
import { Button, Form } from "react-bootstrap";
import { TableRowBase, TableRowProps } from "../../types/Table.interface";
import Settings from "../../pages/home/Settings";
import { ReactComponent as SettingsIcon } from "../../common/assats/table/settings.svg";
import { ReactComponent as BatteryIcon } from "../../common/assats/icons/batteryGreen.svg";
import { ReactComponent as DeleteIcon } from "../../common/assats/table/trash.svg";
import { ReactComponent as EditIcon } from "../../common/assats/table/edit.svg";
import { ReactComponent as LockIcon } from "../../common/assats/table/lock.svg";
import { useTranslation } from "react-i18next";

const TableRow = <T extends TableRowBase>(props: TableRowProps<T>) => {
  const {
    onRowClick,
    checkBoxClick,
    row,
    canEdit,
    canDelete,
    canToggleActive,
    onEditClick,
    onDeleteClick,
    onToggleActionClick,
    extendGapInTable
  } = props;
  const [openSettingsRow, setOpenSettingsRow] = React.useState<string | null>(null);
  const { t } = useTranslation();
  const settingsRef = useRef<HTMLDivElement>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedRow = {
      ...row,
      tableProps: {
        ...row.tableProps,
        selected: e.target.checked,
      },
    };

    checkBoxClick && checkBoxClick(updatedRow);
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (settingsRef.current && !settingsRef.current.contains(event.target as Node)) {
        setOpenSettingsRow(null);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleSettings = (rowId: string) => {
    setOpenSettingsRow(prevId => (prevId === rowId ? null : rowId));
  };

  const actions = [
    canEdit && (
      <Button key="edit" className="settings-button" onClick={(e) => { e.stopPropagation(); onEditClick?.(row) }}>
        <span>{t("general.buttons.edit")}</span> <EditIcon />
      </Button>
    ),
    canDelete && (
      <Button key="delete" className="settings-button" onClick={(e) => { e.stopPropagation(); onDeleteClick?.(row) }}>
        <span>{t("general.buttons.delete")}</span> <DeleteIcon />
      </Button>
    ),
    canToggleActive && (
      <Button key="toggle" className="settings-button" onClick={(e) => { e.stopPropagation(); onToggleActionClick?.(row) }}>
        <span>{t("general.buttons.inactiveActive")}</span> <LockIcon />
      </Button>
    ),
  ].filter(Boolean);


  const keys = Object.keys(props.row).filter((key) => key !== "tableProps");
  return (
    <div className="table-row-main-container">
      <Form.Check
        inline
        id={row.tableProps?.id?.toString()}
        checked={row.tableProps?.selected || false}
        onChange={(e) =>{ e.stopPropagation(); handleChange(e)}}
      />

      {keys.map((key, index) => (
        <div key={index + key.toLowerCase()} className={`table-cell ${extendGapInTable ? "table-cell-extend" : "" }`} title={(row as any)[key]}  onClick={() => onRowClick?.(row)}>
          {!key.includes("battery") ? (
            <span className={`${(key.includes("status") || key.includes("Status")) && !key.includes("_") ? "rounded-status" : ""}`}>
              {(row as any)[key] ?? "N/A"}
            </span>
          ) : (
            <span className="battery-container d-flex align-items-center justify-content-center">
              <span className="battery-icon me-2">
                <BatteryIcon />
              </span>
              {(row as any)[key] ? `${(row as any)[key]}%` : "N/A"}
            </span>
          )}
        </div>
      ))}

      <div className="settings" ref={settingsRef} tabIndex={0}
        onBlur={() => setTimeout(() => setOpenSettingsRow(null), 200)}>
        <SettingsIcon className="settings-icon" onClick={(e) => { e.stopPropagation(); toggleSettings(row.tableProps?.id?.toString() || "") }} />

        {openSettingsRow === row.tableProps?.id?.toString() && (
          <div className="settings-dropdown">
            {actions.map((action, index) => (
              <React.Fragment key={index}>
                {index > 0 && <hr />}
                {action}
              </React.Fragment>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default TableRow;
