import React, { useState } from "react";
import { Doughnut } from "react-chartjs-2";
import "chart.js/auto";
import "../../../styles/components/CircleGraph.scss";
import hexToRgb from "../../../common/function/hexToRgb";

interface CircleGraphProps {
  objectsArray: Array<Record<string, any>>;
  property: string;
}

const predefinedCategories = {
  age: [
    { label: "18–30", min: 18, max: 30 },
    { label: "31–44", min: 31, max: 44 },
    { label: "45–54", min: 45, max: 54 },
    { label: "55–64", min: 55, max: 64 },
    { label: "65–74", min: 65, max: 74 },
    { label: "75–84", min: 75, max: 84 },
    { label: ">85", min: 85, max: Infinity },
  ],
  BMI: [
    { label: "Underweight (< 18.5)", min: 0, max: 18.5 },
    { label: "Normal (18.5–24.9)", min: 18.5, max: 24.9 },
    { label: "Overweight (25–29.9)", min: 25, max: 29.9 },
    { label: "Obese (30–39.9)", min: 30, max: 39.9 },
    { label: "Morbidly obese (> 40)", min: 40, max: Infinity },
  ],
  gender: [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
    { label: "Other", value: "Other" },
  ],
  smokingStatus: [
    { label: "Cessation Over 5yrs", value: "Cessation Over 5yrs" },
    { label: "Cessation Less than 5yrs", value: "Cessation Less than 5yrs" },
    { label: "Over a pack/day", value: "Yes (Over a pack/day)" },
    { label: "Less than a pack/day", value: "Yes (Less than a pack/day)" },
    { label: "No", value: "No" },
  ],
  race: [
    {
      label: "American Indian / Alaska Native",
      value: "American Indian / Alaska Native",
    },
    { label: "Asian", value: "Asian" },
    { label: "Black / African American", value: "Black / African American" },
    { label: "Hispanic / Latino", value: "Hispanic / Latino" },
    {
      label: "Native Hawaiian / Pacific Islander",
      value: "Native Hawaiian / Pacific Islander",
    },
    { label: "White", value: "White" },
    { label: "NA", value: "NA" },
  ],
  isSymptomatic: [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ],
};
const CircleGraph: React.FC<CircleGraphProps> = ({
  objectsArray,
  property,
}) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const categories =
    predefinedCategories[property as keyof typeof predefinedCategories] || [];


  const mappedObjectsArray = objectsArray.map((obj) => {
    if (property === "isSymptomatic" && typeof obj[property] === "boolean") {
      return {
        ...obj,
        [property]: obj[property] ? "Yes" : "No",
      };
    }
    return obj;
  });

  const valueCounts = categories.map((category: any) => {
    if (category.min !== undefined && category.max !== undefined) {
      return mappedObjectsArray.reduce((count, obj) => {
        const value = obj[property];
        if (value >= category.min && value <= category.max) {
          return count + 1;
        }
        return count;
      }, 0);
    } else {
      return mappedObjectsArray.reduce((count, obj) => {
        return obj[property] === category.value ? count + 1 : count;
      }, 0);
    }
  });

  const totalObjects = valueCounts.reduce((sum, count) => sum + count, 0);

  const labels = categories.map((category) => category.label);
  const values = valueCounts.map((count) =>
    ((count / totalObjects) * 100).toFixed(2)
  );

  const colors = [
    "#00C7BE",
    "#2F75DA",
    "#EE4AE7",
    "#6E33ED",
    "#AF52DE",
    "#F12889",
    "#FF9F09",
  ];

  const dataToGraph = {
    labels: labels,
    datasets: [
      {
        data: values,
        backgroundColor: colors.map((color, index) =>
          activeIndex === null || activeIndex === index
            ? color
            : `rgba(${hexToRgb(color)}, 0.2)`
        ),
        hoverBackgroundColor: colors,
        borderRadius: 8,
        borderWidth: 5,
        borderColor: "#fff",
        hoverBorderColor: "#fff",
      },
    ],
  };

  const options = {
    cutout: "68%",
    plugins: {
      legend: { display: false },
      tooltip: { enabled: false },
    },
    onHover: (event: any, chartElement: any[]) => {
      if (chartElement.length) {
        setActiveIndex(chartElement[0].index);
      } else {
        setActiveIndex(null);
      }
    },
    onLeave: () => {
      setActiveIndex(null);
    },
  };

  return (
    <div className="circle-graph-container">
      {objectsArray.length ? (
        <ul className={`legend-list ${labels.length > 5 ? "six-plus" : ""}`}>
          {labels.map((label, index) => (
            <li
              key={label}
              className="label-value-group"
              style={{
                opacity:
                  activeIndex === null || activeIndex === index ? 1 : 0.5,
                transition: "opacity 0.3s ease",
              }}
            >
              <div className="label-color-group">
                <div
                  className="color-marker"
                  style={{
                    backgroundColor: colors[index],
                    opacity:
                      activeIndex === null || activeIndex === index ? 1 : 0.2,
                    transition: "opacity 0.3s ease",
                  }}
                ></div>
                <span data-tooltip={label} title={label}>
                  {label}
                </span>
              </div>
              <strong>{parseInt(values[index]).toFixed(0)}%</strong>
            </li>
          ))}
        </ul>
      ) : null}
      <div className="graph" onMouseLeave={() => setActiveIndex(null)}>
        <Doughnut
          data={dataToGraph}
          options={options}
          className="doughnut-graph"
        />
      </div>
    </div>
  );
};

export default CircleGraph;
