import React, { FC, FunctionComponent, useState } from 'react'
import "../../styles/homePage/table/tableHeaderCards.scss"
import { useTranslation } from 'react-i18next'

import { ReactComponent as Arrow } from '../../common/assats/homeScreens/cardsIcons/arrow.svg';
import { TableCard, TableHeaderTabs } from '../../common/types/tableCardType';
import { TableHeaderParams } from './TableHeader';
import { Tab, Tabs } from 'react-bootstrap';



export interface CardsProps {
    cards?: TableCard[],
    tabs?: TableHeaderTabs[],
    tableHeaderTitle?: string
}
const TableHeaderCards: FC<CardsProps> = ({ cards, tabs, tableHeaderTitle: title }) => {
    const { t } = useTranslation()
    const [activeTab, setActiveTab] = useState<string>("0");


    const tabClickHandler = (key: string | null) => {
        if (!tabs) return;
        const index = Number(key);
        const type = tabs[index]?.type || "";
        tabs[index].action && tabs[index].action(type);
    };

    return (

        <div className='cards-main-container row'>
            {title && title.length ? <h3 className='table-header-title'>{title}</h3> : null}
            {cards?.map((val, index) =>
                <div key={`${val.headline}${index}`} className={`card-container col-4`}>
                    <div className='card-headline-container'>
                        <div className='card-headline-texts'>
                            <p className="card-headline-text">{val.headline}</p>
                            <p className="card-headline-explain">{val.explanation}</p>
                        </div>
                        {<val.icon />}
                    </div>
                    <div className='card-value'>
                        <span className='card-number'>{val.value}</span>
                        {val.action && (
                            <span onClick={val.action} className='card-view-all'>
                                {t(`patientsList.viewAll`)} <Arrow />
                            </span>
                        )}
                    </div>
                </div>
            )}
            {
                tabs && tabs.length > 0 ?
                    <Tabs
                        defaultActiveKey="0"
                        id="uncontrolled-tab-example"
                        activeKey={activeTab}
                        className="mb-3 custom-tabs"
                        onSelect={(key) => {
                            setActiveTab(key || "0");
                            tabClickHandler(key);
                        }}
                    >
                        {tabs.map((tab, index) => (
                            <Tab
                                key={index.toString()}
                                eventKey={index.toString()}
                                title={tab.value}
                            />
                        ))}
                    </Tabs> : null
            }
        </div>
    )
}

export default TableHeaderCards