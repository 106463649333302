import { useEffect, useRef, useState } from "react";
import MainHome from "../../components/MainHome";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useFilters } from "../../common/contexts/FiltersContext";
import { useToast } from "../../common/contexts/useToast";
import { devicesToggleAction, exportData, getDevicesList, postNewFotaToDevice } from "../../common/api/apiCalls";
import { ReactComponent as ActiveSitesIcon } from "../../common/assats/homeScreens/cardsIcons/valid.svg";
import { ReactComponent as NonActiveSitesIcon } from "../../common/assats/homeScreens/cardsIcons/cross.svg";
import { TableCard } from "../../common/types/tableCardType";
import Filter from "../../components/common/Filter/Filter";
import TablePage from "../../components/genericTable/TablePage";
import { DevicesTableDataInterface } from "../../common/interfaces/Devices.interface";
import { useDebouncedSearch } from "../../common/hooks/Search";
import { sysConfig } from "../../config";
import { deviceInfoFields } from "../../components/devices/DeviceInfoFields";
import { formatDate } from "../../common/function/formatDate";
import { FilterOptionInterface } from "../../common/interfaces/filtersObject.interface";
import { RouteEnum } from "../../common/consts/roles";
import useUserStore from "../../common/store/user.store";
import { AxiosResponse } from "axios";
import DynamicModal from "../../components/common/Modal/DynamicModal";
import "../../styles/components/Devices.scss";
import { FOTA } from "../../common/interfaces/FOTA.inteface";


type SortOptions =
  | "deviceSerialNumber"
  | "fw_Version"
  | "fw_Status"
  | "firmwareUpdateDate"
  | "lastSeen"
  | "status"
  | "relatedSite"
  | "swVersion";

const sortKeysArray: SortOptions[] = [
  "deviceSerialNumber",
  "fw_Version",
  "fw_Status",
  "firmwareUpdateDate",
  "lastSeen",
  "status",
  "relatedSite",
  "swVersion",
];

const Devices = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { search, debouncedSearch, handleSearchChange } = useDebouncedSearch(
    sysConfig.DEBOUNCE_TIME
  );
  const [sortKey, setSortKey] = useState<string>("serialNumber");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");

  const [devicesCounts, setDevicesCounts] = useState(0);
  const { activeFilters, clearFilters, handleCardFilters } = useFilters();
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

  const [selectedRows, setSelectedRows] = useState<any[]>([]);

  const { showToast } = useToast();

  const [devicesData, setDevicesData] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [cards, setCards] = useState<TableCard[]>([]);

  const locationState = location.state;
  const sortResetRef = useRef(false);

  const { canAccessCRUD } = useUserStore();
  
  const user = useUserStore((state) => state.user);
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState<any>({});
  const [isDynamicModalOpen, setIsDynamicModalOpen] = useState(false);
  const [availableVersions, setAvailableVersions] = useState<{
    [key in FOTA]: { version: string; id: string }[];
  }>({
    [FOTA.FIRMWARE]: [],
    [FOTA.SOFTWARE]: [],
  });

  const getVersionOptions = () => {
    const selectedFotaType = selectedOptions["type"] as FOTA;
    if (!selectedFotaType) return [];

    return [
      { label: t("general.buttons.selectVersion"), value: "", disabled: true },
      ...(availableVersions[selectedFotaType] || []).map(({ version, id }) => ({
        label: version,
        value: version,
      })),
    ];
  };

  const steps = [
    {
      state: "type",
      title: t("table.version.titles.updateTitle"),
      subtext: t("table.version.subContext.subContext"),
      options: [
        { label: t("table.version.buttons.firmware"), value: FOTA.FIRMWARE },
        { label: t("table.version.buttons.software"), value: FOTA.SOFTWARE },
      ],
      buttons: [
        { label: t("general.buttons.cancel"), value: "cancel" },
        { label: t("general.buttons.next"), value: "next" },
      ],
      requierdSelection: true
    },
    {
      state: "updateVersion",
      title: t("table.version.titles.updateTitleSecond"),
      subtext: t("table.version.subContext.subContextSecond"),
      inputType: "dropdown",
      getOptions: getVersionOptions,
      buttons: [
        { label: t("general.buttons.previous"), value: "previous" },
        { label: t("general.buttons.next"), value: "next" },
      ],
      requierdSelection: true
    },
    {
      state: "updateType",
      title: t("table.version.titles.updateTitleThird"),
      subtext: t("table.version.subContext.subContextThird"),
      options: [
        { label: t("table.version.buttons.forcedUpdate"), value: "forced" },
        { label: t("table.version.buttons.optionalUpdate"), value: "optional" },
      ],
      buttons: [
        { label: t("general.buttons.previous"), value: "previous" },
        { label: t("general.buttons.next"), value: "next" },
      ],
      requierdSelection: true
    },
    {
      state: "selectedData",
      title: t("table.version.titles.updateTitleFourth"),
      subtext: t("table.version.subContext.subContextFourth"),
      buttons: [
        { label: t("general.buttons.previous"), value: "previous" },
        { label: t("general.buttons.updateNow"), value: "updateNow" },
      ]
    },
  ];


  const [filtersObject, setFiltersObject] = useState<FilterOptionInterface>({
    relatedSite: {
      label: t("general.filters.relatedSite"),
      options: [],
    },
    relatedSiteAdmin: {
      label: t("devicesPage.filters.relatedSiteAdmin"),
      options: [],
    },
    fwVersionStatus: {
      label: t("devicesPage.filters.fwVersionStatus"),
      options: [],
    },
    fwVersionUpdateDate: {
      label: t("devicesPage.filters.fwVersionUpdateDate"),
      options: [
        { name: "today", label: "Today" },
        { name: "yesterday", label: "Yesterday" },
        { name: "last7days", label: "Last 7 days" },
        { name: "last30days", label: "Last 30 days" },
        { name: "last3months", label: "Last 3 months" },
        { name: "last12months", label: "Last 12 months" },
        { name: "custom", label: "Custom" },
      ],
      inputType: "date",
    },
    status: {
      label: t("general.filters.status"),
      options: [
        { name: t("general.filters.active"), label: "Active" },
        { name: t("general.filters.inactive"), label: "Inactive" },
      ],
    },
  });

  useEffect(() => {
    if (locationState?.filters?.key && locationState?.filters?.value) {
      handleCardFilters(locationState.filters.key, locationState.filters.value);
      resetPagination();
    }
  }, [location.pathname, sortKey, sortOrder, debouncedSearch]);

  useEffect(() => {
    fetchData();
  }, [sortKey, sortOrder, activeFilters, debouncedSearch, page]);

  useEffect(() => {
    setPage(1);
    setDevicesData([]);
    setHasMore(true);
  }, [sortKey, sortOrder]);

  const fetchData = async () => {
    const response = await getDevicesList({
      sortKey,
      sortOrder,
      filters: activeFilters,
      search: debouncedSearch,
      page,
      limit: 10,
    });
    if (response?.success && response?.data) {
      const filteredData = filterData(response.data.devices);
      if (locationState?.filters?.key || page === 1) {
        setDevicesData(filteredData);
      } else {
        setDevicesData((prevData) => [...prevData, ...filteredData]);
      }
      setIsFetchingMore(false);
      setAvailableVersions({
        [FOTA.FIRMWARE]: response.data?.fota?.firmwareVersion,
        [FOTA.SOFTWARE]: response.data?.fota?.softwareVersion,
      })
    } else if (response?.data?.length === 0) {
      setHasMore(false);
      setIsFetchingMore(false);
    }
    setDevicesCounts(response?.data?.count);
    setFilterData(response.data?.sites, response.data?.users, response.data?.fwVersions);
    setCardsData(
      response.data?.activeDevicesCount,
      response.data?.nonActiveDevicesCount
    );
    sortResetRef.current = false;
  };

  const setFilterData = (sites: string[], users: string[], fwVersions: string[]) => {
    setFiltersObject((prevFilters) => ({
      ...prevFilters,
      relatedSite: {
        ...prevFilters.relatedSite,
        options: sites?.map((site: string) => ({
          name: site,
          label: site,
        })),
      },
      relatedSiteAdmin: {
        ...prevFilters.relatedSiteAdmin,
        options: users?.map((user: string) => ({
          name: user,
          label: user,
        })),
      },
      fwVersionStatus: {
        ...prevFilters.fwVersionStatus,
        options: fwVersions?.map((status: string) => ({
          name: status,
          label: status,
        })),
      }
    }));
  };

  const setCardsData = (
    activeDevicesCount: number,
    nonActiveDevicesCount: number
  ) => {
    setCards([
      {
        headline: t("devicesPage.cards.activeDevices"),
        explanation: t("patientsList.cards.afterExamExplain"),
        icon: ActiveSitesIcon,
        value: activeDevicesCount || 0,
        action: () => {
          handleCardFilters("status", "Active");
          resetPagination();
        },
      },
      {
        headline: t("devicesPage.cards.nonActiveDevices"),
        explanation: t("patientsList.cards.afterExamExplain"),
        icon: NonActiveSitesIcon,
        value: nonActiveDevicesCount || 0,
        action: () => {
          handleCardFilters("status", "Inactive");
          resetPagination();
        },
      },
    ]);
  };

  const navigateToView = (deviceData: DevicesTableDataInterface) => {
    const device = devicesData.find(
      (device: any) => device.tableProps.id === deviceData.tableProps.id
    );
    if (!device) {
      showToast(
        "error",
        t("general.toast.error"),
        t("general.errors.generalError")
      );
      return;
    }
    const displayData = {
      relatedSite: device.tableProps?.site,
      relatedSiteAdmin: device?.tableProps?.relatedSiteAdmin,
      fw_version: device.fw_version,
      sw_version: device.sw_version,
      hwId: device.tableProps?.hwId,
    };

    const deviceEditStateData = {
      id: deviceData.tableProps.id,
      deviceSerialNumber: device.deviceSerialNumber,
      relatedSite: device.relatedSite,
      relatedSiteAdmin: device?.tableProps?.relatedSiteAdmin,
      fw_version: device.fw_version,
      sw_version: device.sw_version,
      hwId: device.tableProps.hwId,
    }
    navigate(`/Home/Devices/View/${deviceData.tableProps.id}`, {
      state: {
        displayData: deviceInfoFields(displayData),
        informationData: {
          title: device.deviceSerialNumber,
          batteryStatus: device.tableProps?.batteryStatus,
          pathToEdit: `/Home/Devices/Edit/${deviceData.tableProps.id}`,
          status: device?.status,
          editPermission: RouteEnum.DevicesEdit,
        },
        editState: deviceEditStateData,
      },
    });
  };

  const onEditClick = (row: any) => {
    const device = devicesData.find(
      (device: any) => device.tableProps.id === row.tableProps.id
    );
    if (!device) {
      showToast(
        "error",
        t("general.toast.error"),
        t("general.errors.generalError")
      );
      return;
    }

    navigate(`/Home/Devices/Edit/${row.tableProps.id}`, {
      state: {
        id: device.tableProps.id,
        deviceSerialNumber: device.deviceSerialNumber,
        relatedSite: device.relatedSite,
        relatedSiteAdmin: device?.tableProps?.relatedSiteAdmin,
        fw_version: device.fw_version,
        sw_version: device.sw_version,
        hwId: device.tableProps.hwId,
      },
    });
  }

  const onToggleActionClick = async (row: any) => {
    const deviceId = row.tableProps.id;
    const device = devicesData.find(
      (device: any) => device.tableProps.id === deviceId
    );
    if (!device || !device?.status) {
      showToast(
        "error",
        t("general.toast.error"),
        t("general.errors.generalError")
      );
      return;
    }

    const response = await devicesToggleAction([deviceId]);
    if (response?.success) {
      showToast("success", t("general.toast.success"), response.message);
      resetPagination();
      fetchData();
    } else {
      showToast("error", t("general.toast.error"), t("general.errors.toggleActionError"));
    }
  }

  const filterData = (data: any) => {
    const filterdedData = data.map((device: any) => ({
      deviceSerialNumber: device.deviceSerialNumber,
      fw_version: device?.fwFOTA?.version,
      fw_status: device?.lastFirmwareUpdate?.status|| "N/A",
      firmwareUpdateDate: formatDate(device?.lastFirmwareUpdate?.assignedAt) || "N/A",
      lastSeen: device.lastSeen ? formatDate(device.lastSeen) : "N/A",
      status: device?.status,
      relatedSite: device.site.siteName,
      sw_version: device?.swFOTA?.version,
      tableProps: {
        id: device.id,
        selected: false,
        site: device.site.siteName,
        hwId: device.hwId,
        relatedSiteAdmin: device?.deviceAdmin?.username,
        batteryStatus: device?.batteryStatus,
      },
    }));
    return filterdedData;
  };

  const handleCheckBoxClick = (updatedRow: any) => {
    const rowId = updatedRow.tableProps.id;

    const updateRows = devicesData.map((row: any) => {
      if (row.tableProps.id === rowId) {
        return {
          ...row,
          tableProps: {
            ...row.tableProps,
            selected: updatedRow.tableProps.selected,
          },
        };
      }
      return row;
    });

    setDevicesData(updateRows);
    if (updatedRow.tableProps.selected) {
      setSelectedRows([...selectedRows, updatedRow]);
    } else {
      setSelectedRows(
        selectedRows.filter((row: any) => row.tableProps.id !== rowId)
      );
    }
  };

  const handleFetchMore = () => {
    if (!isFetchingMore && hasMore && !sortResetRef.current) {
      setIsFetchingMore(true);
      setPage((prev) => prev + 1);
    }
  };

  const resetPagination = () => {
    setPage(1);
    setDevicesData([]);
    setHasMore(true);
    setIsFetchingMore(false);
  };

  const handleSortChange = (key: string, order: "asc" | "desc") => {
    const isKeyChanged = sortKey !== key;
    const isOrderChanged = sortOrder !== order;

    if (!isKeyChanged && !isOrderChanged) return;

    sortResetRef.current = true;
    setDevicesData([]);
    setHasMore(true);
    setSortKey(key);
    setSortOrder(order);
    setPage(1);
  };

  const handleCheckAll = (checked: boolean) => {
    const updatedRows = devicesData.map((row) => {
      return {
        ...row,
        tableProps: {
          ...row.tableProps,
          selected: checked,
        },
      };
    });

    setDevicesData(updatedRows);
    setSelectedRows(checked ? updatedRows : []);
  };

  const exportDevices = async (type: string) => {
    try {
      const params = {
        type: "devices",
        formats: type.toLowerCase(),
        sortKey,
        sortOrder,
        filters: activeFilters,
        search: debouncedSearch,
      };
      const response = await exportData("export", params);
      if (response.success && response.data) {
        const axiosResponse = response.data as AxiosResponse<any, any>;

        const contentDisposition = axiosResponse.headers["content-disposition"];
        const contentType = axiosResponse.headers["content-type"];
        const fileName = contentDisposition
          ? contentDisposition.split("filename=")[1]?.replace(/['"]/g, "")
          : contentType.includes("json")
            ? "export.json"
            : contentType.includes("spreadsheet")
              ? "export.xlsx"
              : "export.zip";

        const blob = new Blob([axiosResponse.data], { type: contentType });

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();

        link.remove();
        window.URL.revokeObjectURL(url);
      } else {
        showToast(
          "error",
          t("general.toast.error"),
          t("general.export.exportGeneralError")
        );
      }

    } catch (error) {
      showToast(
        "error",
        t("general.toast.error"),
        t("general.export.exportGeneralError")
      );
    }
  }


  const nextStep = () => {
    if (currentStep < steps.length - 1) {
      if (steps[currentStep].requierdSelection && !selectedOptions[steps[currentStep].state]) {
        showToast("error", t("general.toast.error"), t("general.errors.fillAllFields"))
        return;
      }
      setCurrentStep(currentStep + 1);
    }
  };

  const prevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleSelection = (value: any) => {
    const optionKey = steps[currentStep].state;
    setSelectedOptions((prev: any) => ({
      ...prev,
      [optionKey]: value,
    }));
  };

  const handleUpdateNow = async () => {
    const selectedDevicesId:string[] = selectedRows.map((row) => row.tableProps.id);
    const selectedFota = availableVersions[selectedOptions["type"] as FOTA].find(
      (version) => version.version === selectedOptions["updateVersion"]
    );
    if(!selectedFota?.id || !selectedDevicesId.length) {
      showToast("error", t("general.toast.error"), t("general.errors.generalError"));
      return;
    }
    const dataToSend = {
      devicesId: selectedDevicesId,
      fotaId: selectedFota.id as string,
      type: selectedOptions["type"] as string,
      updateType: selectedOptions["updateType"] as string,
    }
    const response = await postNewFotaToDevice(dataToSend);
    if (response.success) {
      showToast("success", t("general.toast.success"), response.message);
      reset();
    } else {
      showToast("error", t("general.toast.error"), response.message);
    }
  };

  const reset = () => {
    handleCloseModal();
    resetPagination();
    fetchData();
    setSelectedRows([]);
  }

  const handleButtonClick = (value: string) => {
    if (value === "next") {
      nextStep();
    } else if (value === "previous") {
      prevStep();
    } else if (value === "updateNow") {
      handleUpdateNow();
    } else if (value === "cancel") {
      handleCloseModal();
    }
  };

  const handleOpenModal = () => {
    if (selectedRows.length === 0) {
      showToast("error", t("general.toast.error"), t("general.errors.selectRowsToContinueWithAction"));
      return;
    } else {
      setIsDynamicModalOpen(true);
    }
  }

  const handleCloseModal = () => {
    setIsDynamicModalOpen(false);
    setCurrentStep(0);
    setSelectedOptions({});
  };



  return (
    <MainHome>
      <div className="devices-page-modal">
        {isDynamicModalOpen && (
          <DynamicModal
            titleInTheHeader={true}
            title={steps[currentStep].title}
            subtext={steps[currentStep].subtext}
            onClose={handleCloseModal}
            buttonText={steps[currentStep].buttons[1]?.label}
            onButtonClick={() => handleButtonClick(steps[currentStep].buttons[1]?.value)}
            secondaryButtonText={steps[currentStep].buttons[0]?.label}
            onSecondaryButtonClick={() => handleButtonClick(steps[currentStep].buttons[0]?.value)}
          >
            {steps?.[currentStep]?.options && (steps?.[currentStep]?.options ?? []).length > 0 && steps[currentStep].inputType !== "dropdown" && (
              <div className="d-flex flex-column align-items-start">
                {steps?.[currentStep]?.options?.map((option) => (
                  <label key={option.value} style={{ display: "block", marginBottom: "10px" }}>
                    <input
                      type="radio"
                      name="updateType"
                      value={option.value}
                      checked={selectedOptions[steps[currentStep].state] === option.value}
                      onChange={() => handleSelection(option.value)}
                      className="me-3"
                    />
                    {option.label}
                  </label>
                ))}
              </div>
            )}

            {steps[currentStep].inputType === "dropdown" && (
              <select
                onChange={(e) => handleSelection(e.target.value)}
                style={{ width: "100%", padding: "8px", marginTop: "10px" }}
              >
                {(steps[currentStep].getOptions?.() || []).map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            )}

            {currentStep === steps.length - 1 && (
              <div className="d-flex flex-column align-items-start">
                {Object.entries(selectedOptions).map(([key, value]) => (
                  <span key={key}>
                    <strong>{t(`table.version.details.${key}`)}:</strong> {String(value)}
                  </span>
                ))}
              </div>
            )}
          </DynamicModal>
        )}
      </div>
      <Filter
        isOpen={isFilterOpen}
        filtersObject={filtersObject}
        handleFilterClose={() => {
          setIsFilterOpen(false);
        }}
        filterTrigger={resetPagination}
      />
      <TablePage
        hasMore={hasMore}
        isFetchingMore={isFetchingMore}
        fetchMore={handleFetchMore}
        handleCheckAll={(e) => {
          handleCheckAll(e);
        }}
        tableHeadline={t(`devicesPage.title`)}
        headerHeadline={t("devicesPage.devices")}
        viewAllAction={() => {
          clearFilters();
          resetPagination();
        }}
        cards={cards}
        createPath="DevicesCreate"
        totalCount={devicesCounts}
        filterAction={() => {
          setIsFilterOpen(!isFilterOpen);
        }}
        searchValue={search}
        setSearchValue={handleSearchChange}
        searchPlaceholder={t("devicesPage.filters.search")}
        buttonText={t("devicesPage.create.title")}
        buttonAction={() => {
          navigate("/Home/Devices/Create");
        }}
        setSortKey={(key) => handleSortChange(key, sortOrder)}
        setSortOrder={(order) => handleSortChange(sortKey, order)}
        sortKey={sortKey}
        sortOrder={sortOrder}
        tableData={devicesData}
        onCheckBoxClick={handleCheckBoxClick}
        sortKeysArray={
          sortKeysArray
            ? sortKeysArray.map((value) => {
              return { name: t(`devicesPage.sort.${value}`), val: value };
            })
            : []
        }
        onRowClick={navigateToView}
        canEdit={canAccessCRUD(RouteEnum.DevicesEdit, user?.role)}
        canToggleActive={canAccessCRUD(RouteEnum.DevicesToggleAction, user?.role)}
        onEditClick={(row: any) => { onEditClick(row) }}
        onToggleActionClick={(row: any) => { onToggleActionClick(row) }}
        exportAction={exportDevices}
        isExport={true}
        versionAction={canAccessCRUD(RouteEnum.FotaUpdateVersion, user?.role) ? handleOpenModal : undefined}
      />
    </MainHome>
  );
};

export default Devices;
