import * as Yup from "yup";
import { SiteStepsEnum } from "../enums/generalEnum";
import { phoneNumberValidation } from "./ValidationSchemas";


export const siteValidationSchemas: Record<SiteStepsEnum, Yup.ObjectSchema<any>> = {
  [SiteStepsEnum.SITE_INFORMATION]: Yup.object().shape({
    siteName: Yup.string().required("sitePage.siteCreate.formErrors.siteNameRequired"),
    siteNumber: Yup.string().required("sitePage.siteCreate.formErrors.siteNumberRequired"),
    siteAdminFullName: Yup.string().required("sitePage.siteCreate.formErrors.siteAdminFullNameRequired"),
    siteAdminEmail: Yup.string()
      .email("sitePage.siteCreate.formErrors.suteAdminEmailNotValid")
      .required("sitePage.siteCreate.formErrors.suteAdminEmailRequired"),
    siteAdminPhone: Yup.string()
      .max(15, "sitePage.siteCreate.formErrors.suteAdminPhoneNotValid")
      .required("sitePage.siteCreate.formErrors.suteAdminPhoneRequired")
      .test(
        "is-valid-phone",
        "Phone number is not valid.",
        (value) => phoneNumberValidation(value)
      ),
    siteStatus: Yup.string(), //.required("sitePage.siteCreate.formErrors.siteStatusRequired"),
    metrics: Yup.string(), //.required("sitePage.siteCreate.formErrors.siteMetricsRequired")
  }),

  [SiteStepsEnum.SITE_ADDRESS]: Yup.object().shape({
    address: Yup.string().required("sitePage.siteCreate.formErrors.siteAddressRequired"), //Add validation for format
    city: Yup.string().required("sitePage.siteCreate.formErrors.siteCityRequired"),
    state: Yup.string().when("country", ([country], schema) => {
      return country === "United States"
        ? schema.required("sitePage.siteCreate.formErrors.stateRequired")
        : schema.notRequired();
    }),
    zip: Yup.string(),
    country: Yup.string()
    .required("sitePage.siteCreate.formErrors.siteCountryRequired")
  }),
};
