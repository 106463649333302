import React, { useState } from "react";
import MainHome from "../../components/MainHome";
import { useTranslation } from "react-i18next";
import { useToast } from "../../common/contexts/useToast";
import useUserStore from "../../common/store/user.store";
import { sysConfig } from "../../config";
import CustomDropdown from "../../components/common/inputs/CustomDropdown";
import "../../styles/components/SettingsPage.scss";
import CustomChipInput from "../../components/common/inputs/CustomChipInput";
import { updateUserSettings } from "../../common/api/apiCalls";

const Settings = () => {
  const { user } = useUserStore();
  const { t } = useTranslation();
  const { showToast } = useToast();

  const [systemLanguage, setSystemLanguage] = useState<string>("English");
  const [chipOptions, setChipOptions] = useState<string[]>(user?.predefinedEmails || []);
  const [currentChipValue, setCurrentChipValue] = useState<string>("");

  const languageArr = sysConfig.LANGUAGE_OPTIONS.map((option) => option.label);

  const handleAddChip = () => {
    if (!currentChipValue.trim()) return;
  
    const isCommaSeparated = currentChipValue.includes(",");
  
    const newEmails = currentChipValue
      .split(",") 
      .map((email) => email.trim())
      .filter((email) => email !== "");
  
    
    const invalidEmails = newEmails.filter(
      (email) => !sysConfig.EMAIL_PATTERN.test(email)
    );

    const invalidFormat = newEmails.map(email => email.split("@")?.length > 2);

    if (!isCommaSeparated && newEmails.length > 1 || invalidFormat.includes(true)) {
      showToast("error", "Error", t("general.errors.invalidEmailsChip"));
      return;
    }
  
    if (invalidEmails.length > 0) {
      showToast("error", "Error", t("general.errors.emailNotValidChip"));
      return;
    }
  
    const updatedChips = Array.from(new Set([...chipOptions, ...newEmails]));
    setChipOptions(updatedChips);
    setCurrentChipValue("");
    submitPredefinedEmails(updatedChips);
  };

  const handleRemoveChip = (chip: string) => {
    const updatedChips = chipOptions.filter((e) => e !== chip);
    setChipOptions(updatedChips);
    submitPredefinedEmails(updatedChips);
  };

  const submitPredefinedEmails = async (emails:string[]) => {
    await updateUserSettings({ systemLanguage, predefinedEmails: emails });
    showToast("success", "Success", t("settings.emailUpdated"));
  };

  return (
    <MainHome>
      <div className="settings-container">
        <div className="header-section pt-5 pb-3">
          <h2 className="fw-bold">{t("menu.settings")}</h2>
        </div>
        <hr />
        <div className="language-section mb-4">
          <h3 className="mt-3">{t("settings.siteLanguage")}</h3>
          <span>{t("settings.siteLanguageDescription")}</span>
          <div className="input-container mt-3 mb-3">
            <CustomDropdown
              name={"language"}
              label={""}
              optionsArray={languageArr}
              value={systemLanguage}
              handleInputChange={(e: any) => setSystemLanguage(e.target.value)}
            />
          </div>
        </div>
        <hr />
        <div className="email-section">
          <h3 className="mt-3">{t("settings.emailList")}</h3>
          <span>{t("settings.emailListDescription")}</span>
          <CustomChipInput
            placeHolder="general.placeholders.emailPlaceHolder"
            chipOptions={chipOptions}
            currentValue={currentChipValue}
            setCurrentValue={setCurrentChipValue}
            onAddChip={handleAddChip}
            onRemoveChip={handleRemoveChip}
            infoMessage={t("general.errors.infoEmailChip")}
            isInfoMessage={true}
          />
        </div>
      </div>
    </MainHome>
  );
};

export default Settings;
