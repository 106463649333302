import { roleDisplayNames, roles } from "../consts/roles";


interface PatientMetrics {
    metrics: string;
    height: number;
    weight: number;
}


export function debounce(func: (...args: any[]) => void, delay: number) {
    let timeoutId: NodeJS.Timeout | null;

    return (...args: any[]) => {
        if (timeoutId) {
            clearTimeout(timeoutId); // Clear any existing timeout
        }
        timeoutId = setTimeout(() => {
            func(...args); // Execute the function after the delay
        }, delay);
    };
}



export function getFirstLettersOfFirstTwoWords(sentence: string): string {
    const words = sentence.split(' ');
    const firstTwoWords = words.slice(0, 2);
    return firstTwoWords.map(word => word.charAt(0)).join('');
}


export function getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}


export function getRoleDisplayNames(isFlat?: boolean, role?: string) {
    if (isFlat) {
        return Object.values(roleDisplayNames);
    }

    return role ? roleDisplayNames[role as keyof typeof roleDisplayNames] : roleDisplayNames;
}


export function getRolesByRoleName(role: string) {
    switch (role) {
        case roles.SuperAdmin: //Acculine admin
            return [
                roleDisplayNames.SiteAdmin,
                roleDisplayNames.ClinicalUser,
                roleDisplayNames.NonClinicalUser
            ];
        case roles.SiteAdmin: //Super user
            return [
                roleDisplayNames.ClinicalUser,
                roleDisplayNames.NonClinicalUser,
                roleDisplayNames.SiteAdmin
            ];
        case roles.RootUser:
            return [
                roleDisplayNames.SuperAdmin,
                roleDisplayNames.SiteAdmin,
                roleDisplayNames.ClinicalUser,
                roleDisplayNames.NonClinicalUser
            ];
        default:
            return [
                roleDisplayNames.SiteAdmin,
                roleDisplayNames.ClinicalUser,
                roleDisplayNames.NonClinicalUser
            ];
    }
}


export function roleByRole(role: string) {
    switch (role) {
        case roles.SuperAdmin: //Acculine admin
            return [
                roles.SiteAdmin,
                roles.ClinicalUser,
                roles.NonClinicalUser
            ];
        case roles.RootUser: //Acculine admin
            return [
                roles.SuperAdmin,
                roles.SiteAdmin,
                roles.ClinicalUser,
                roles.NonClinicalUser
            ];
        case roles.SiteAdmin: //Super user
            return [
                roles.ClinicalUser,
                roles.NonClinicalUser,
                roles.SiteAdmin
            ];
        case roles.ClinicalUser:
            return [
                roles.ClinicalUser
            ]
        case roles.NonClinicalUser:
            return [
                roles.NonClinicalUser
            ]
        default:
            return [
                roles.SiteAdmin,
                roles.ClinicalUser,
                roles.NonClinicalUser
            ];
    }
}


export function calculateBMI({ metrics, height, weight }: PatientMetrics): number {
    if (metrics === "metric") {
        const heightInMeters = height / 100;
        return +(weight / (heightInMeters * heightInMeters)).toFixed(2);
    } else if (metrics === "imperial") {
        const heightInInches = height * 12;
        return +((weight * 703) / (heightInInches * heightInInches)).toFixed(2);
    } else {
        return 0
    }
}