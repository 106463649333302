import React from "react";
import { TextField, Chip, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import "../../../styles/components/TextInput.scss";

export interface CustomChipInputProps {
  placeHolder?: string;
  customError?: string;
  infoMessage?: string;
  isInfoMessage?: boolean;
  chipOptions: string[]; // Controlled by Settings
  currentValue: string;
  setCurrentValue: (value: string) => void;
  onAddChip: () => void;
  onRemoveChip: (value: string) => void;
}

const CustomChipInput: React.FC<CustomChipInputProps> = ({
  placeHolder = "general.placeholders.generalInputPlaceHolder",
  customError = "general.errors.invalidInput",
  infoMessage,
  isInfoMessage = false,
  chipOptions,
  currentValue,
  setCurrentValue,
  onAddChip,
  onRemoveChip,
}) => {
  const { t } = useTranslation();

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      onAddChip();
    }
  };

  return (
    <div className="custom-chip-input-container">
      <Box>
        <Box sx={{ display: "flex", flexDirection: "column", mb: 1, mt: 4 }}>
          <TextField
            variant="outlined"
            label={t(placeHolder)}
            value={currentValue}
            onChange={(e) => setCurrentValue(e.target.value)}
            fullWidth
            onKeyDown={handleKeyDown}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
                "&:hover fieldset": { borderColor: "#1A5DBA" },
                "&.Mui-focused fieldset": { borderColor: "#0047AB" },
              },
              "& .MuiInputLabel-root": { color: "#2F75DA" },
              "& .MuiInputLabel-root.Mui-focused": { color: "#0047AB" },
            }}
          />
          {isInfoMessage && infoMessage && <div className="info-input">{t(infoMessage)}</div>}
        </Box>

        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
          {chipOptions.map((email, index) => (
            <Chip
              key={index}
              label={email}
              onDelete={() => onRemoveChip(email)}
              color="primary"
              variant="outlined"
            />
          ))}
        </Box>
      </Box>
    </div>
  );
};

export default CustomChipInput;
