export const sysConfig = {
    searchDebounce: 1000,
    menuSetTimeUserText: 110,
    DEBOUNCE_TIME: 1000,
    NUMERIC_PATTERN: /^[0-9]*$/,
    RANGE_TIMESTAMP_REGEX: /^(0?[1-9]|1[0-2])\/(0?[1-9]|[12][0-9]|3[01])\/\d{4} - (0?[1-9]|1[0-2])\/(0?[1-9]|[12][0-9]|3[01])\/\d{4}$/,
    EMAIL_PATTERN: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    PHONE_NUMBER_PATTERN: /^(?:\+1[2-9]\d{9}|\+972(?:[2-9]\d{7,8}))$/, //TODO change so it will be international
    LANGUAGE_OPTIONS: [ { value: "en", label: "English" }],
    PASSWORD_SPECIAL_CHAR_PATTERN: /[!@#$%^&*(),.?":{}|<>_\-+=~`\\[\]\/]/,
    NUMBER_PATTERN: /\d/,
    CAPITAL_LETTER_PATTERN: /[A-Z]/,
    VERSION_INPUT_REGEX: /^\d+(\.\d+)*\.?$/,
    APITIMEOUT: 10000,
}