import React, { useReducer, useEffect } from "react";
import "../../styles/components/SitesPage.scss";
import "../../styles/components/General.scss";
import { useTranslation } from "react-i18next";
import FormBuilder, { FormBuilderInterface } from "../common/FormBuilder";
import { ReactComponent as SearchIcon } from "../../common/assats/table/search.svg";
import { getCountriesArray, US_STATES } from "../../common/function/getCountriesArr";

// Define action types for reducer
const SET_COUNTRY = "SET_COUNTRY";

// Initial form configuration
const initialState: FormBuilderInterface[] = [
  {
    name: "address",
    label: "sitePage.siteCreate.address",
    type: "text",
    required: true,
    translateOffset: false,
    translateLabel: true,
    fullRow: true,
  },
  {
    name: "city",
    label: "sitePage.siteCreate.city",
    type: "text",
    required: true,
    translateOffset: false,
    translateLabel: true,
  },
  {
    name: "state",
    type: "text",
    label: "State",
    icon: <SearchIcon />,
  },
  {
    name: "zipCode",
    label: "sitePage.siteCreate.zipCode",
    type: "text",
    required: false,
    translateOffset: false,
    translateLabel: true,
  },
  {
    name: "country",
    label: "sitePage.siteCreate.country",
    type: "autoComplete",
    optionsArray: getCountriesArray(true) as string[],
    required: true,
    translateOffset: false,
    translateLabel: true,
    icon: <SearchIcon />,
  },
];

// Reducer function to handle form state updates
const formReducer = (state: FormBuilderInterface[], action: { type: string; payload: string }) => {
  switch (action.type) {
    case SET_COUNTRY:
      return state.map((field) => {
        if (field.name === "state") {
          return {
            ...field,
            type: action.payload === "United States" ? "autoComplete" : "text",
            required: action.payload === "United States",
            optionsArray: action.payload === "United States" ? US_STATES.map((state) => state.name) : [],
          };
        }
        return field;
      });
    default:
      return state;
  }
};

interface SiteInformationCreateProps {
  values: { [key: string]: any };
  handleInputChange: (name: string, value: any) => void;
  errors: { [key: string]: string };
}

const SiteAddressCreate = ({ values, handleInputChange, errors }: SiteInformationCreateProps) => {
  const { t } = useTranslation();

  const [formFields, dispatch] = useReducer(formReducer, initialState);

  useEffect(() => {
    const selectedCountry = values?.country || "";
    dispatch({ type: SET_COUNTRY, payload: selectedCountry });

    if (selectedCountry !== "United States") {
      handleInputChange("state", "");
    }
  }, [values?.country]);

  return (
    <div>
      <FormBuilder
        fields={formFields}
        handleInputChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleInputChange(e.target.name, e.target.value)
        }
        values={values}
        showErrors={true}
        errors={errors}
        translateErrors={true}
      />
    </div>
  );
};

export default SiteAddressCreate;
