import React, { useEffect, useRef, useState } from "react";
import "../../../styles/components/Dropdown.scss";
import { createPortal } from "react-dom";


interface CustomDropdownWithTextInputProps {
  value: string;
  required?: boolean;
  showErrors?: boolean;
  name: string;
  handleInputChange: any;
  handleFocus?: any;
  handleBlur?: (name: string) => void;
  label?: string;
  optionsArray: Array<string>;
  withPlaceHolder?: boolean;
  error?: string;
  disabled?: boolean;
  allowCustomInput?: boolean;
  customInputTrigger?: string; // The option that triggers text input (e.g., "Other")
}

const CustomDropdownWithTextInput: React.FC<CustomDropdownWithTextInputProps> = ({
  name,
  value,
  required,
  showErrors,
  handleInputChange,
  handleFocus,
  handleBlur,
  label,
  optionsArray,
  disabled,
  withPlaceHolder = true,
  error,
  allowCustomInput = true,
  customInputTrigger = "Other",
}) => {
  const [isCustomInput, setIsCustomInput] = useState(value === customInputTrigger);
  const [customText, setCustomText] = useState(value);
  const [showDropdown, setShowDropdown] = useState(false);
  const [listOfOptions, setListOfOptions] = useState(optionsArray);

  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleDropdownChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value?.toLocaleLowerCase();
    if (allowCustomInput && selectedValue === customInputTrigger.toLocaleLowerCase()) {
      setIsCustomInput(true);
      setCustomText("");
      handleInputChange(e);
      const filteredOptions = listOfOptions.filter((option) => option !== customInputTrigger);
      setListOfOptions(filteredOptions);
    } else {
      setIsCustomInput(false);
      handleInputChange(e);
    }
  };

  const handleTextInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomText(e.target.value);
    handleInputChange(e);
  };

  const handleSelect = (selectedValue: string) => {
    setCustomText(selectedValue);
    setIsCustomInput(false);
    setShowDropdown(false);
    handleInputChange({ target: { name, value: selectedValue } });
    setListOfOptions(optionsArray);
  }


  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!event.target || !(event.target as HTMLElement).closest(".dropdown-wrapper")) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);


  useEffect(() => {
    if(value && !optionsArray.includes(value) && value !== customInputTrigger) {
      setCustomText(value);
      setIsCustomInput(true);
    }
  }, [value]);


  const hasLabel = !!label;
  return (
    <div className={`dropdown-wrapper ${hasLabel ? "set-label" : ""} ${error && showErrors ? "invalid-field" : ""}`}>
      {withPlaceHolder && label && (
        <span className="custom-dropown-label">
          {label}
          {required && <span className="required-asterisk">*</span>}
        </span>
      )}

      {!isCustomInput ? (
        <select
          className={`form-control custom-select ${showErrors && required && !value && error ? "is-invalid" : ""}`}
          name={name}
          value={value || ""}
          onChange={handleDropdownChange}
          onBlur={() => handleBlur && handleBlur(name)}
          onFocus={handleFocus}
          disabled={disabled}
        >
          <option value="" disabled hidden>
            {""}
          </option>
          {listOfOptions.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </select>
      ) : (
        <div className="autocomplete-container">
          <input
            type="text"
            ref={inputRef}
            className="form-control custom-input"
            name={name}
            value={customText}
            onChange={handleTextInputChange}
            onBlur={() => handleBlur && handleBlur(name)}
            onFocus={() => setShowDropdown(true)}
          />
          {/* {showDropdown && optionsArray.length > 0 && (
            <ul className="autocomplete-options">
              {optionsArray.map((option, index) => (
                <li key={index} onMouseDown={() => handleSelect(option)} className="autocomplete-option">
                  {option}
                </li>
              ))}
            </ul>
          )} */}

          {showDropdown &&
            createPortal(
              <ul
                className={"autocomplete-options"}
                style={{
                  position: "absolute",
                  left: inputRef.current ? `${inputRef.current.getBoundingClientRect().left}px` : "0px",
                  top: inputRef.current ? `${inputRef.current.getBoundingClientRect().bottom + window.scrollY}px` : "0px",
                  width: inputRef.current ? `${inputRef.current.offsetWidth}px` : "100%",
                  maxHeight: "200px",
                  overflowY: "auto",
                  background: "white",
                  border: "1px solid #ddd",
                  borderRadius: "8px",
                  zIndex: 999999999999,
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  padding: "0",
                  margin: "0",
                  listStyle: "none",
                }}
              >
                {listOfOptions.map((option, index) => (
                  <li
                    style={{
                      padding: "5px 16px",
                      fontSize: "1rem",
                      color: "black",
                      cursor: "pointer",
                      transition: "background-color 0.2s",
                    }}
                    key={index} onMouseDown={() => handleSelect(option)} className="autocomplete-option">
                    {option}
                  </li>
                ))}
              </ul>,
              document.body
            )}
        </div>
      )}

      <div className="custom-dropdown-arrow">
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="9" viewBox="0 0 14 9" fill="none">
          <path
            d="M7 8C6.91244 8 6.8318 7.9833 6.75806 7.9499C6.67972 7.9165 6.60829 7.86639 6.54378 7.79959L1.17972 2.12372C1.05991 1.99489 1 1.83981 1 1.65849C1 1.53442 1.02765 1.42468 1.08295 1.32924C1.13825 1.22904 1.21429 1.15031 1.31106 1.09305C1.40323 1.03102 1.50922 1 1.62903 1C1.79954 1 1.94931 1.06442 2.07834 1.19325L7 6.40389L11.9217 1.19325C12.0507 1.06442 12.2005 1 12.371 1C12.4908 1 12.5968 1.03102 12.6889 1.09305C12.7857 1.15031 12.8618 1.22904 12.9171 1.32924C12.9724 1.42468 13 1.53442 13 1.65849C13 1.83981 12.9378 1.99489 12.8134 2.12372L7.45622 7.79959C7.39171 7.86639 7.32258 7.9165 7.24885 7.9499C7.17051 7.9833 7.08756 8 7 8Z"
            fill="#C8C8C8"
            stroke="#757575"
            strokeWidth="1.5"
          />
        </svg>
      </div>

      {showErrors && error && <div className="invalid-feedback">{error}</div>}
    </div>
  );
};

export default CustomDropdownWithTextInput;
