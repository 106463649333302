import axios, { AxiosResponse, Method } from "axios";
import Cookies from "js-cookie";
import { generateSHA512Hash } from "../utils/hashUtil";
import { sysConfig } from "../../config";

const urlsPath =
  process.env.REACT_APP_BACKEND_API_URL ||
  "http://localhost:8000/api/v1/webapp";

let refreshToken: string | null = null;
let patientIndex: string | null = null;

const apiInstance = axios.create({
  timeout: sysConfig.APITIMEOUT,
  headers: {
    Accept: "*/*",
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

// ========================================
// Request Interceptor - Attach Hash to the Header
// ========================================
apiInstance.interceptors.request.use(
  async (config) => {
    if (config.method !== "get" && config.data) {
      const hash = generateSHA512Hash(config.data);
      config.headers["body-hash"] = hash;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// ========================================
// Response Interceptor - Validate Response Hash
// ========================================
apiInstance.interceptors.response.use(
  async (response) => {
    if (response?.headers["body-hash"]) {
      const responseHash = response.headers["body-hash"];
      const calculatedHash = generateSHA512Hash(response.data);

      if (responseHash !== calculatedHash) {
        console.error("Response hash verification failed!");
        return Promise.reject(new Error("Response hash verification failed"));
      }
    }
    return response;
  },
  async (error) => {
    if (error.response?.status === 403 || error.response?.status === 401) {
      if (error.config?.url !== "/users/Login") {
        localStorage.removeItem("access_token");
        Cookies.remove("access_token");
        window.location.href = "/";
      }
    }
    return Promise.reject(error);
  }
);

setAPIBaseURL();

const setRefreshedAuthData = async (
  authToken: string,
  rToken: string,
  patientI?: string
) => {
  setAPIAuthToken(authToken);
  setAPIRefreshToken(rToken);
  if (patientI) patientIndex = patientI;
};

function setAPIAuthToken(authToken: string) {
  apiInstance.defaults.headers.common.Authorization = `${authToken}`;
}

const setAPIRefreshToken = async (rToken: string) => {
  refreshToken = `${rToken}`;
  await localStorage.setItem("refreshToken", rToken);
};

function removeAPIAuthToken() {
  refreshToken = null;
  delete apiInstance.defaults.headers.common.Authorization;
}

function setAPITimeout(timeout: number) {
  apiInstance.defaults.timeout = timeout;
}

function setAPIBaseURL() {
  apiInstance.defaults.baseURL = urlsPath;
}

async function API_Root(url: string, method: Method, data: any) {
  const dataJson = JSON.stringify(data, null, 2);
  try {
    return await apiInstance.request({
      url,
      method,
      data: dataJson,
      validateStatus: function (status_1) {
        return (
          (status_1 >= 200 && status_1 < 300) ||
          (status_1 >= 400 && status_1 < 500) ||
          status_1 == 504
        );
      },
    });
  } catch (error: any) {
    return await Promise.reject(error);
  }
}

/*=============================GENERIC FUNCTIONS======================================*/

async function makePostRequest(url: string, data: any) {
  try {
    const res = await apiInstance.request({
      url,
      method: "POST",
      data,
    });
    if (url === "/users/Login") {
      await localStorage.setItem("access_token", res?.data?.data?.access_token);
    }

    return res;
  } catch (error) {
    return await Promise.reject(error);
  }
}

async function makeGetRequest(url: string, params: any = undefined) {
  try {
    return await apiInstance.request({
      url,
      method: "GET",
      params,
    });
  } catch (error) {
    return await Promise.reject(error);
  }
}

async function makeExportRequest(url: string, params: any = undefined) {
  try {
    return await apiInstance.request({
      url,
      method: "POST",
      responseType: "blob",
      params,
    });
  } catch (error) {
    return await Promise.reject(error);
  }
}

async function makePostRequestForDownload(
  url: string,
  data: any
): Promise<AxiosResponse<any>> {
  try {
    const res = await apiInstance.request({
      url,
      method: "POST",
      data,
      responseType: "arraybuffer",
    });
    return res;
  } catch (error) {
    return await Promise.reject(error);
  }
}

async function makeDeleteRequest(url: string, data: any = {}) {
  try {
    return await apiInstance.request({
      url,
      method: "DELETE",
      data,
    });
  } catch (error) {
    return await Promise.reject(error);
  }
}

async function makeUploadRequest(url: string, formData: FormData) {
  try {
    const response = await apiInstance.request({
      url,
      method: "POST",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      validateStatus: (status_1) =>
        (status_1 >= 200 && status_1 < 300) ||
        (status_1 >= 400 && status_1 < 500),
    });

    return response;
  } catch (error) {
    return await Promise.reject(error);
  }
}

export {
  urlsPath,
  API_Root,
  makeGetRequest,
  makePostRequest,
  setAPIRefreshToken,
  setAPIAuthToken,
  removeAPIAuthToken,
  setAPITimeout,
  makeUploadRequest,
  makeDeleteRequest,
  setRefreshedAuthData,
  refreshToken,
  patientIndex,
  makeExportRequest,
  makePostRequestForDownload,
};
