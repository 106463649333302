import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import MainHome from "../../MainHome";
import InfoFieldsBuilder, {
  InfoFieldsBuilderInterface,
} from "../InfoFieldsBuilder";
import "../../../styles/components/generalInformation.scss";
import { ReactComponent as BatteryIcon } from "../../../common/assats/icons/batteryGreen.svg";
import { canAccessRoute } from "../../../common/function/roleRoutePermissions";
import useUserStore from "../../../common/store/user.store";
import {
  getRolesByRoleName,
  roleByRole,
} from "../../../common/function/generalFunctions";
import { Role } from "../../../common/consts/roles";

type InformationDataType = {
  displayData: InfoFieldsBuilderInterface[];
  editState: any;
  informationData: {
    title: string;
    status: string;
    batteryStatus?: string;
    textArray: string[];
    icon?: string;
    pathToEdit: string;
    editPermission: string;
    userToEditRole: string;
  };
};

const GeneralInformation: FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const [isEditDisabled, setIsEditDisabled] = React.useState(true);
  const { user, getUser, canAccessCRUD } = useUserStore();

  const locationState = location.state as InformationDataType;
  const informationData = locationState?.informationData;
  const data = locationState?.displayData;

  useEffect(() => {
    if (informationData && data) {
      setIsAddDisabledState();
    }
  }, [location]);

  const setIsAddDisabledState = async () => {
    let user = await getUser();
    const role = user?.role;
    if (!role) return;
    const path = (informationData && informationData.editPermission) || "";
    const hasAccess = canAccessRoute(role, path);

    const hasRoleOprationAccess = canAccessCRUD(path, role);

    if (hasAccess && hasRoleOprationAccess) {
      setIsEditDisabled(false);
    }
  };

  const editOnClick = () => {
    navigate(informationData.pathToEdit, { state: locationState.editState });
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <MainHome>
      <div className="general-container">
        <div className="general-header ">
          <div className="d-flex">
            {/* <div className="back-button-container ">
              <div
                onClick={handleBackClick}
                className="back-span"
              >
                {t("general.buttons.back")}
              </div>
            </div> */}
            <div className="col-4">
              <div className="d-flex flex-row align-items-end">
                <h1 className="pe-3">{informationData.title}</h1>
                {isEditDisabled ? null : (
                  <span className="pb-3" onClick={editOnClick}>
                    {t("general.edit")}
                  </span>
                )}
              </div>
              {locationState.informationData?.batteryStatus ? (
                <div className="min-content d-flex me-2">
                  <div className="rounded-status">{informationData.status}</div>
                  <span className="battery-status d-flex align-items-center justify-content-center ms-3">
                    <BatteryIcon />%{informationData?.batteryStatus}
                  </span>
                </div>
              ) : (
                <div className="min-content">
                  <div className="rounded-status">{informationData.status}</div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="information-content">
          <InfoFieldsBuilder fields={data} />
        </div>
      </div>
    </MainHome>
  );
};

export default GeneralInformation;
