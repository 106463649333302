const magicNumbers: { [key: string]: string[] } = {
  "image/png": ["89", "50", "4E", "47", "0D", "0A", "1A", "0A"],
  "application/pdf": ["25", "50", "44", "46"],
  "application/vnd.debian.binary-package": [
    "21",
    "3C",
    "61",
    "72",
    "63",
    "68",
    "3E",
    "0A",
  ],
};

async function getFileMagicNumber(file: File): Promise<string[]> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (reader.result instanceof ArrayBuffer) {
        const bytes = new Uint8Array(reader.result.slice(0, 8));
        const hex = Array.from(bytes).map((byte) =>
          byte.toString(16).toUpperCase().padStart(2, "0")
        );
        resolve(hex);
      } else {
        reject("Failed to read file");
      }
    };
    reader.onerror = () => reject("Error reading file");
    reader.readAsArrayBuffer(file.slice(0, 8));
  });
}

export async function validateFile(file: File): Promise<boolean> {
  try {
    const magicNumber = await getFileMagicNumber(file);

    for (const [fileType, expectedMagicNumbers] of Object.entries(
      magicNumbers
    )) {
      const isValid =
        magicNumber.slice(0, expectedMagicNumbers.length).join("") ===
        expectedMagicNumbers.join("");

      if (isValid) {
        return true;
      }
    }
    return false;
  } catch (error) {
    return false;
  }
}
